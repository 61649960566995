import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';

export default function FadeMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  function handleClick(event) {
    console.log(event);
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div>
      <Button fullWidth aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick} style={{color: '#544cf8', fontSize: 21, borderColor: '#544cf8', borderWidth: '2px', marginBottom: 20, height: 50, borderRadius: 25, backgroundColor: 'rgb(249,250,252)', borderStyle: 'dashed', boxShadow: "0px 10px 15px -10px rgba(0,0,0,0.00)"}}>
        +
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
      {props.main.state.exposures4 == false && (
        <MenuItem onClick={()=> {props.main.setState({exposures4: true}); setAnchorEl(null)}}>4 Exposures</MenuItem>
      )}
      {props.main.state.exposures5 == false && (
        <MenuItem onClick={()=> {props.main.setState({exposures5: true}); setAnchorEl(null)}}>5 Exposures</MenuItem>
      )}
      {props.main.state.exposures6 == false && (
        <MenuItem onClick={()=> {props.main.setState({exposures6: true}); setAnchorEl(null)}}>6 Exposures</MenuItem>
      )}
      {props.main.state.exposures7 == false && (
        <MenuItem onClick={()=> {props.main.setState({exposures7: true}); setAnchorEl(null)}}>7 Exposures</MenuItem>
      )}
      {props.main.state.exposures8 == false && (
        <MenuItem onClick={()=> {props.main.setState({exposures8: true}); setAnchorEl(null)}}>8 Exposures</MenuItem>
      )}
      {props.main.state.exposures9 == false && (
        <MenuItem onClick={()=> {props.main.setState({exposures9: true}); setAnchorEl(null)}}>9 Exposures</MenuItem>
      )}
      </Menu>
    </div>
  );
}
