// B1 - business and B2 - visitor
// L1 -
// E2 - Invester Visa
// E1 - International Trader
// National Interest
// Lottery

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, lighten } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import UploadAndLastUpload from "./UploadAndLastUpload.js";
import Cookies from 'universal-cookie';
import uuid from 'react-native-uuid';
import ImageUploader from 'react-images-upload';
import '../../App.css';
import LinearProgress from '@material-ui/core/LinearProgress';
// import RawOrBrackets from './RawOrBrackets.js';
import {DropzoneArea} from 'material-ui-dropzone';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Delete from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
// import MuiDialogTitle from '@material-ui/core/DialogTitle';
// import MuiDialogContent from '@material-ui/core/DialogContent';
// import MuiDialogActions from '@material-ui/core/DialogActions';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Gallery from 'react-grid-gallery';
import Dropzone from "./Dropzone/Dropzone";
import Input from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// import GifLoader from 'react-gif-loader';
import Avatar from '@material-ui/core/Avatar';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Lightbox from 'react-image-lightbox';
// import ReactImageProcess from "react-image-process";
import { AuthController } from 'react-controllers'
// import LazyLoad from 'react-lazy-load';
import LazyLoad from 'react-lazyload'
import Tooltip from '@material-ui/core/Tooltip';
import Upload3Exosures from './Upload/3Exposures.js';
import Upload4Exosures from './Upload/4Exposures.js';
import Upload5Exosures from './Upload/5Exposures.js';
import Upload6Exosures from './Upload/6Exposures.js';
import Upload7Exosures from './Upload/7Exposures.js';
import Upload8Exosures from './Upload/8Exposures.js';
import Upload9Exosures from './Upload/9Exposures.js';
import Upload1Exosures from './Upload/RAWS.js';
import EditedImages from './Upload/EditedImages.js';
import Uploaded3Exosures from './Uploaded/3Exposures.js';
import Uploaded4Exosures from './Uploaded/4Exposures.js';
import Uploaded5Exosures from './Uploaded/5Exposures.js';
import Uploaded6Exosures from './Uploaded/6Exposures.js';
import Uploaded7Exosures from './Uploaded/7Exposures.js';
import Uploaded8Exosures from './Uploaded/8Exposures.js';
import Uploaded9Exosures from './Uploaded/9Exposures.js';
import Uploaded1Exosures from './Uploaded/RAWS.js';
import ExposuresMenu from './ExposuresMenu.js';
import { withSnackbar } from 'notistack';
import CommentsDialog from './CommentsDialog.js';
import Map from './Map';
import qs from 'qs';

import WarningIcon from '@material-ui/icons/Warning';
import { MuiPickersUtilsProvider, DatePicker, TimePicker } from 'material-ui-pickers';
import PersonIcon from '@material-ui/icons/Person';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import CalendarTodayIcon from '@material-ui/icons/DateRange';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import Notifications from '@material-ui/icons/Notifications';

import 'react-image-lightbox/style.css';
const fs = require('fs');
const FileDownload = require('js-file-download');
const BASE_URL = 'https://py.wesnapp.co.il/server/upload/';
// const GET_RAW_IMAGES = 'https://py.wesnapp.co.il/server/get-compressed-raws/';
// const GET_BRACKETS_IMAGES = 'https://py.wesnapp.co.il/server/get-compressed-brackets/';
// const GET_EDITED_IMAGES = 'https://py.wesnapp.co.il/server/get-edited/';
// const GET_EDITED_WATERMARKED_IMAGES = 'https://py.wesnapp.co.il/server/get-edited-watermarked/';
const GET_ANGEL_IMAGES = 'https://py.wesnapp.co.il/server/get-angel-images/';

const GET_BRACKETS_ORGENIZED_IMAGES = 'https://py.wesnapp.co.il/server/get-images-orgenized/'
const GET_EDITED_ORGENIZED_IMAGES = 'https://py.wesnapp.co.il/server/get-edited-images-orgenized/'

const uploadIcon = require("./uploadIcon.png");
const downloadIcon = require("./downloadIcon.png");

// const loadingGif = require("./loadingGif.gif");
const vIcon = require("./vIcon.png");
const menuIcon = require("./menuIcon.png");
const xIcon = require("./xIcon.png");
const zoomIn = require("./zoomIn.png");

//https://www.iconfinder.com/icons/2561262/cloud_download_icon
const trashIcon = require("./trashTableIcon.png");
const zoomInIcon = require("./zoomInTableIcon.png");
const downloadTIcon = require("./downloadTableIcon.png");
const cameraIcon = require("./cameraTableIcon.png");

const dateIcon = require("./dateIcon.png");
const locationIcon = require("./locationIcon.png");
const userIcon = require("./userIcon.png");
const imageIcon = require("./imageIcon.png");

const alertIcon = require("./alertIcon.png");
const successIcon = require("./successIcon.png");
const zipIcon = require("./zipIcon.png");

const zoomInIconBig = require("./zoomin_icon.png");

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    borderRadius: 20,
    backgroundColor: 'rgb(249,250,252)'
  },
  bar: {
    borderRadius: 20,
    backgroundColor: '#04e259',
  },
})(LinearProgress);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

var aws = require('aws-sdk');

aws.config.update({
  region: 'us-east-2', // Put your aws region here
  accessKeyId: 'AKIAJPBGH7WBDCF5KZIA',
  secretAccessKey: 'iYQNbM5cjDoFW3llkIXtFzJrgZDKL8wpzGeOLMW2'
})

const S3_BUCKET = "wesnapp-development"

const styles = theme => ({
  root: {
    paddingLeft: 40, paddingRight: 40,
    backgroundColor: 'white',
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
  },
  margin: {
    margin: 10
  }
});

class NewsPage extends React.Component {
  state = {
    prepearingImages: false,
    lat: 0,
    lng: 0,
    urlRawsImages: [],
    uploadStation: 'Raws',
    urlBracketsImages: [],
    loading: false,
    loadingData: true,
    exposure: 3,
    bracketsFiles: [],
    rawsFiles: [],
    bracketsImages: [],
    deleting: false,
    rawsImages: [],
    requestId: '',
    uploadAndLastUpload: 'Upload',
    rawsPhotoIndex: 0,
    bracketsPhotoIndex: 0,
    rawsIsOpen: false,
    angelsPhotoIndex: 0,
    angelsIsOpen: false,
    bracketsIsOpen: false,
    uploadBracketsInProgress: false,
    uploadRawsInProgress: false,
    enableChangingExposure: false,
    openAlert: false,
    openSuccess: false,
    galleryOrLinesEdited: true,
    regularOrWatermarked: false,
    contactName: '',
    shootingLocation: '',
    dateAndTime: '',
    productName: '',
    avatar: '',
    mobilePhone: '',
    station: 'infused',
    uploadEditedInProgress: false,
    editedFiles: [],
    editedPhotoIndex: 0,
    editedIsOpen: false,
    angelTouchImages: [],
    angelTouchImagesOriginals: [],
    urlEditedImages: [],
    imagesForGallery: [],
    watermarkIsOpen: false,
    watermarkPhotoIndex: 0,
    editedLIsOpen: false,
    editedLPhotoIndex: 0,
    watermarkedImagesForGallery: [],
    requestName: '',
    uploadBracketsFinished: true,
    uploadRawsFinished: true,
    editedWatermarkedImages: [],
    atImagesForGallery: [],
    atImagesForGalleryInfuesd: [],
    atImagesForGalleryWB: [],
    atImagesForGalleryWB_LAB: [],
    atImagesForGalleryLC: [],
    galleryOrLinesAT: false,
    galleryOrLinesUpload: false,
    loadingATImages: true,
    photographerName: '',
    isDeleting: false,
    urls_wb: [],
    urls_wb_lab: [],
    urls_lc: [],
    urls_infused: [],
    uploadedExposures: '',
    downloadPercenteges: 0,
    exposures4: false,
    exposures5: false,
    exposures6: false,
    exposures7: false,
    exposures8: false,
    exposures9: false,
    editedImages: {thumbnail: [], originals: [], compressed: [], watermarked: [], loading: true, imagesForGallery: []},
    images1Exposures: {thumbnail: [], originals: [], compressed: [], loading: true, imagesForGallery: []},
    images3Exposures: {thumbnail: [], originals: [], compressed: [], loading: true, imagesForGallery: []},
    images4Exposures: {thumbnail: [], originals: [], compressed: [], loading: true, imagesForGallery: []},
    images5Exposures: {thumbnail: [], originals: [], compressed: [], loading: true, imagesForGallery: []},
    images5Exposures: {thumbnail: [], originals: [], compressed: [], loading: true, imagesForGallery: []},
    images6Exposures: {thumbnail: [], originals: [], compressed: [], loading: true, imagesForGallery: []},
    images7Exposures: {thumbnail: [], originals: [], compressed: [], loading: true, imagesForGallery: []},
    images8Exposures: {thumbnail: [], originals: [], compressed: [], loading: true, imagesForGallery: []},
    images9Exposures: {thumbnail: [], originals: [], compressed: [], loading: true, imagesForGallery: []},
    displayGallery: false,
    openMenu: false,
    commentDialogOpen: false,
    comment: "",
    addingCommentInProgress: false,
    screen: "1",
    isLoading: false,
  };

   componentDidMount() {
    this.getDataOfRequest()
  }

  addComment = () => {
    this.setState({addingCommentInProgress: true})
    var data = {requestId: this.state.requestId, data: {WeSnapp_Team_Comments__c: this.state.comment}}
    //console.log(data);
    axios.post('https://wesnapp.co.il/api/request/update-request',data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      //console.log(response.data);
      this.setState({addingCommentInProgress: false, commentDialogOpen: false})
      this.commentAddedSuccessfully()
    })
    .catch( (error) => {
      //console.log(error);
    });
  }

  commentAddedSuccessfully = () => {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    this.props.enqueueSnackbar("The comment was successfully added", { variant: "success", autoHideDuration: 9999999999999999, resumeHideDuration: 99999999999999,
    action: key => (
                  <Button color="secondary" style={{color: 'white'}} size="small" onClick={() => this.props.closeSnackbar(key)}>
                      Dismiss
                  </Button>
          )}
        )
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  sendSMS = () => {
    var phone = '+972' + this.state.mobilePhone
    console.log(phone);
    var data = {code: '+972', text: 'All images successfully uploaded!', phone: phone}
    axios.post("https://wesnapp.co.il/api/sms/send-message",data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data);
      })
    .catch( (error) => {
      console.log(error);
    });
  }

  editImages = (type) => {
    var data = {RequestId: this.state.requestId, Type: type, _id: '5d1df5ef970a5524f0752996'}
    console.log("EDIT PROJECT");
    axios.post("https://wesnapp-edit-server.appspot.com/server/edit-images" ,qs.stringify(data), {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}).then((response) => {
        console.log(response.data);
      })
    .catch( (error) => {
      console.log(error);
    });
  }

  downloadOneImage = (url, name) => {
    console.log('download-image');
    var win = window.open(url);
    console.log(url);
  }

  deleteImage = (deleteFrom, index) => {
    var originalPath = ''
    if (deleteFrom == '3Exposures') {
      var images = this.state.images3Exposures
      originalPath = images.originals[index]
      images.compressed.splice(index, 1);
      images.thumbnail.splice(index, 1);
      images.originals.splice(index, 1);
      images.imagesForGallery.splice(index, 1);
      this.setState({images3Exposures: images})
    }
    if (deleteFrom == '4Exposures') {
      var images = this.state.images4Exposures
      originalPath = images.originals[index]
      images.compressed.splice(index, 1);
      images.thumbnail.splice(index, 1);
      images.originals.splice(index, 1);
      images.imagesForGallery.splice(index, 1);
      this.setState({images4Exposures: images})
    }
    if (deleteFrom == '5Exposures') {
      var images = this.state.images5Exposures
      originalPath = images.originals[index]
      images.compressed.splice(index, 1);
      images.thumbnail.splice(index, 1);
      images.originals.splice(index, 1);
      images.imagesForGallery.splice(index, 1);
      this.setState({images5Exposures: images})
    }
    if (deleteFrom == '6Exposures') {
      var images = this.state.images6Exposures
      originalPath = images.originals[index]
      images.compressed.splice(index, 1);
      images.thumbnail.splice(index, 1);
      images.originals.splice(index, 1);
      images.imagesForGallery.splice(index, 1);
      this.setState({images6Exposures: images})
    }
    if (deleteFrom == '7Exposures') {
      var images = this.state.images7Exposures
      originalPath = images.originals[index]
      images.compressed.splice(index, 1);
      images.thumbnail.splice(index, 1);
      images.originals.splice(index, 1);
      images.imagesForGallery.splice(index, 1);
      this.setState({images7Exposures: images})
    }
    if (deleteFrom == '8Exposures') {
      var images = this.state.images8Exposures
      originalPath = images.originals[index]
      images.compressed.splice(index, 1);
      images.thumbnail.splice(index, 1);
      images.originals.splice(index, 1);
      images.imagesForGallery.splice(index, 1);
      this.setState({images8Exposures: images})
    }
    if (deleteFrom == '9Exposures') {
      var images = this.state.images9Exposures
      originalPath = images.originals[index]
      images.compressed.splice(index, 1);
      images.thumbnail.splice(index, 1);
      images.originals.splice(index, 1);
      images.imagesForGallery.splice(index, 1);
      this.setState({images9Exposures: images})
    }
    if (deleteFrom == '1Exposures') {
      var images = this.state.images1Exposures
      originalPath = images.originals[index]
      images.compressed.splice(index, 1);
      images.thumbnail.splice(index, 1);
      images.originals.splice(index, 1);
      images.imagesForGallery.splice(index, 1);
      this.setState({images1Exposures: images})
    }
    this.setState({isDeleting: true})
    var data = {path: originalPath.replace('https://s3.amazonaws.com/wesnapp-development/','')}
    console.log(data);
    axios.post("https://wesnapp.co.il/py/delete-file" ,data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data);
        this.setState({isDeleting: false})
      })
    .catch( (error) => {
      console.log(error);
    });
  }

  deleteEditedImage = (index) => {
    var originalPath = ''
    var images = this.state.editedImages
    originalPath = images.originals[index]
    images.compressed.splice(index, 1);
    images.thumbnail.splice(index, 1);
    images.originals.splice(index, 1);
    images.watermarked.splice(index, 1);
    images.imagesForGallery.splice(index, 1);
    this.setState({editedImages: images})
    this.setState({isDeleting: true})
    var data = {path: originalPath.replace('https://s3.amazonaws.com/wesnapp-development/','')}
    console.log(data);
    axios.post("https://wesnapp.co.il/py/delete-file" ,data, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        console.log(response.data);
        this.setState({isDeleting: false})
      })
    .catch( (error) => {
      console.log(error);
    });
  }

  getDataOfRequest = async () => {
    try {
        var id = this.getParameterByName('id')
        if (id != null && id != undefined && id != "") {
          this.setState({requestId: id})
          var data = {id: id}
          const response = await axios.post("https://wesnapp.co.il/api/request/get-full-data", data);
          console.log(response.data);
          var dateAndTime = await this.getDateAndTime(response.data.request.Shooting_Date_Time__c)
          this.setState({loadingData: false})
          this.getCoordinates(response.data.request.ShootingStreet__c + " " + response.data.request.ShootingCity__c)
          if (response.data.request.WeSnapp_Team_Comments__c != null) {
            this.setState({WeSnapp_Team_Comments__c: response.data.request.WeSnapp_Team_Comments__c})
          }
          this.setState({requestData: response.data, photographerName: response.data.request.Photographer_Name__c, requestName: response.data.request.Name, contactName: response.data.contact.Name, mobilePhone: response.data.contact.MobilePhone, shootingLocation: response.data.request.ShootingStreet__c + " " + response.data.request.ShootingCity__c, productName: response.data.product.Public_Product_Name__c, avatar: "AA"}, () => {
            // const response1 =  Promise.All((resp)=>axios.all([this.getEditedImages(),  this.get1Exposures(),  this.get3Exposures(),  this.getAngelTouchImages(),  this.get4Exposures(),  this.get5Exposures(),  this.get6Exposures(),  this.get7Exposures(),  this.get8Exposures(),  this.get9Exposures() ]))
            if (response.data.request.Type_Of_Upload__c != null) {
              this.setState({uploadedExposures: response.data.request.Type_Of_Upload__c})
            } else {
              this.setState({uploadedExposures: ""})
            }
          })
        } else {
            this.setState({loading: false})
            alert('Shooting ID is not valid.')
        }
          this.getEditedImages()
          this.getAngelTouchImages()
          this.get1Exposures()
          this.get3Exposures()
          this.get4Exposures()
          this.get5Exposures()
          this.get6Exposures()
          this.get7Exposures()
          this.get8Exposures()
          this.get9Exposures()
      } catch (err) {
          console.error(err);
      }
  };

  async getData() {
    const promise1 = await axios.get(GET_EDITED_ORGENIZED_IMAGES + this.state.requestId + '-edited?id=1');
    const promise2 = await axios.get(GET_BRACKETS_ORGENIZED_IMAGES + this.state.requestId + '-originals-RAWS?id=2');
    const promise3 = await axios.get(GET_BRACKETS_ORGENIZED_IMAGES + this.state.requestId + '-originals-bracketing-3Exposures?id=3');
    const promise4 = await axios.get(GET_BRACKETS_ORGENIZED_IMAGES + this.state.requestId + '-originals-bracketing-4Exposures?id=4');
    const promise5 = await axios.get(GET_BRACKETS_ORGENIZED_IMAGES + this.state.requestId + '-originals-bracketing-5Exposures?id=5');
    const promise6 = await axios.get(GET_BRACKETS_ORGENIZED_IMAGES + this.state.requestId + '-originals-bracketing-6Exposures?id=6');
    const promise7 = await axios.get(GET_BRACKETS_ORGENIZED_IMAGES + this.state.requestId + '-originals-bracketing-7Exposures?id=7');
    const promise8 = await axios.get(GET_BRACKETS_ORGENIZED_IMAGES + this.state.requestId + '-originals-bracketing-8Exposures?id=8');
    const promise9 = await axios.get(GET_BRACKETS_ORGENIZED_IMAGES + this.state.requestId + '-originals-bracketing-9Exposures?id=9');
  }

  async getEditedImages  ()  {
    const response = await axios.get(GET_EDITED_ORGENIZED_IMAGES + this.state.requestId + '-edited',{}, {headers: {'Content-Type': 'application/json'}});
    console.log(response.data)
    var data = response.data
    var imagesForGallery = []
    for (var i = 0; i < data.watermarked.length; i++) {
      imagesForGallery.push({
        src: data.watermarked[i],
        thumbnail: data.watermarked[i],
        thumbnailWidth: 320,
        thumbnailHeight: 174,
        caption: "Image " + String(i)
      })
    }
    data['loading'] = false
    data['imagesForGallery'] = imagesForGallery
    console.log(data);
    this.setState({editedImages: data})
    return ""
  }

  async get1Exposures () {
    const response = await axios.get(GET_BRACKETS_ORGENIZED_IMAGES + this.state.requestId + '-originals-RAWS',{}, {headers: {'Content-Type': 'application/json'}});
    console.log(response.data)
    var data = response.data
    var imagesForGallery = []
    for (var i = 0; i < data.compressed.length; i++) {
      imagesForGallery.push({
        src: data.compressed[i],
        thumbnail: data.thumbnail[i],
        thumbnailWidth: 320,
        thumbnailHeight: 174,
        caption: "Image " + String(i)
      })
    }
    data['loading'] = false
    data['imagesForGallery'] = imagesForGallery
    this.setState({images1Exposures: data})
    return ""
  }

  async get3Exposures () {
    const response = await axios.get(GET_BRACKETS_ORGENIZED_IMAGES + this.state.requestId + '-originals-bracketing-3Exposures',{}, {headers: {'Content-Type': 'application/json'}});
    console.log(response.data)
    var data = response.data
    var imagesForGallery = []
    for (var i = 0; i < data.compressed.length; i++) {
      imagesForGallery.push({
        src: data.compressed[i],
        thumbnail: data.thumbnail[i],
        thumbnailWidth: 320,
        thumbnailHeight: 174,
        caption: "Image " + String(i)
      })
    }
    data['loading'] = false
    data['imagesForGallery'] = imagesForGallery
    this.setState({images3Exposures: data})
    return ""
  }
   async get4Exposures  () {
     const response = await axios.get(GET_BRACKETS_ORGENIZED_IMAGES + this.state.requestId + '-originals-bracketing-4Exposures',{}, {headers: {'Content-Type': 'application/json'}});
     console.log(response.data)
     var data = response.data
     var imagesForGallery = []
     for (var i = 0; i < data.compressed.length; i++) {
       imagesForGallery.push({
         src: data.compressed[i],
         thumbnail: data.thumbnail[i],
         thumbnailWidth: 320,
         thumbnailHeight: 174,
         caption: "Image " + String(i)
       })
     }
     data['loading'] = false
     data['imagesForGallery'] = imagesForGallery
     this.setState({images4Exposures: data})
     return ""
  }
   async get5Exposures () {
     const response = await axios.get(GET_BRACKETS_ORGENIZED_IMAGES + this.state.requestId + '-originals-bracketing-5Exposures',{}, {headers: {'Content-Type': 'application/json'}});
     console.log(response.data)
     var data = response.data
     var imagesForGallery = []
     for (var i = 0; i < data.compressed.length; i++) {
       imagesForGallery.push({
         src: data.compressed[i],
         thumbnail: data.thumbnail[i],
         thumbnailWidth: 320,
         thumbnailHeight: 174,
         caption: "Image " + String(i)
       })
     }
     data['loading'] = false
     data['imagesForGallery'] = imagesForGallery
     this.setState({images5Exposures: data})
     return ""
  }
   async get6Exposures () {
     const response = await axios.get(GET_BRACKETS_ORGENIZED_IMAGES + this.state.requestId + '-originals-bracketing-6Exposures',{}, {headers: {'Content-Type': 'application/json'}});
     console.log(response.data)
     var data = response.data
     var imagesForGallery = []
     for (var i = 0; i < data.compressed.length; i++) {
       imagesForGallery.push({
         src: data.compressed.length[i],
         thumbnail: data.thumbnail[i],
         thumbnailWidth: 320,
         thumbnailHeight: 174,
         caption: "Image " + String(i)
       })
     }
     data['loading'] = false
     data['imagesForGallery'] = imagesForGallery
     this.setState({images6Exposures: data})
     return ""

  }
   async get7Exposures () {
     const response = await axios.get(GET_BRACKETS_ORGENIZED_IMAGES + this.state.requestId + '-originals-bracketing-7Exposures',{}, {headers: {'Content-Type': 'application/json'}});
     console.log(response.data)
     var data = response.data
     var imagesForGallery = []
     for (var i = 0; i < data.compressed.length; i++) {
       imagesForGallery.push({
         src: data.compressed[i],
         thumbnail: data.thumbnail[i],
         thumbnailWidth: 320,
         thumbnailHeight: 174,
         caption: "Image " + String(i)
       })
     }
     data['imagesForGallery'] = imagesForGallery
     data['loading'] = false
     this.setState({images7Exposures: data})
     return ""
  }
   async get8Exposures () {
     const response = await axios.get(GET_BRACKETS_ORGENIZED_IMAGES + this.state.requestId + '-originals-bracketing-8Exposures',{}, {headers: {'Content-Type': 'application/json'}});
     console.log(response.data)
     var data = response.data
     var imagesForGallery = []
     for (var i = 0; i < data.compressed.length; i++) {
       imagesForGallery.push({
         src: data.compressed[i],
         thumbnail: data.thumbnail[i],
         thumbnailWidth: 320,
         thumbnailHeight: 174,
         caption: "Image " + String(i)
       })
     }
     data['loading'] = false
     data['imagesForGallery'] = imagesForGallery
     this.setState({images8Exposures: data})
     return ""
  }
   async get9Exposures () {
     const response = await axios.get(GET_BRACKETS_ORGENIZED_IMAGES + this.state.requestId + '-originals-bracketing-9Exposures',{}, {headers: {'Content-Type': 'application/json'}});
     var data = response.data
     var imagesForGallery = []
     for (var i = 0; i < data.compressed.length; i++) {
       imagesForGallery.push({
         src: data.compressed[i],
         thumbnail: data.thumbnail[i],
         thumbnailWidth: 320,
         thumbnailHeight: 174,
         caption: "Image " + String(i)
       })
     }
     data['loading'] = false
     data['imagesForGallery'] = imagesForGallery
     this.setState({images9Exposures: data})
     return ""
  }

  downloadZip = (array) => {
    console.log(array);
    var lineOfFiles = ''
    for (var i = 0; i < array.length; i++) {
      var path = ''
      console.log(array[i].url);
      path = array[i].url.replace('https://s3.amazonaws.com/wesnapp-development/', '')
      path.replace('/','-')
      if (lineOfFiles == '') {
        lineOfFiles = path
      } else {
        lineOfFiles = lineOfFiles + ',' + path
      }
    }
     var win = window.open('https://py.wesnapp.co.il/server/zipfiles?path=' + lineOfFiles, '_blank');
  }

  uploadDownloadProgress = () => {
    return (progress) => {
      var percentCompleted = Math.round((progress.loaded * 100) / progress.total);
      this.setState({downloadPercenteges: percentCompleted, prepearingImages: false}, () => {
      })
    }
  }

  downloadZipFile = (array, zipname) => {
    console.log(array);
    if (this.state.downloadPercenteges == 0) {
      var lineOfFiles = ''
      for (var i = 0; i < array.length; i++) {
        var path = ''
        console.log(array[i]);
        path = array[i].replace('https://s3.amazonaws.com/wesnapp-development/', '')
        path.replace('/','-')
        if (lineOfFiles == '') {
          lineOfFiles = path
        } else {
          lineOfFiles = lineOfFiles + ',' + path
        }
      }
      this.setState({downloadPercenteges: 1, prepearingImages: true})
      var config = {
        responseType: 'blob',
        onDownloadProgress: this.uploadDownloadProgress()
      };
      var filesOld = lineOfFiles.split(',')
      var files = []
      var folder = filesOld[0].split("/" + filesOld[0].split('/')[filesOld[0].split('/').length - 1])[0]
      for (var j = 0; j < filesOld.length; j++) {
        files.push(filesOld[j].split(folder + '/')[1])
      }


      console.log('folder');
      console.log(folder);
      console.log(files);
      axios.post('https://py.wesnapp.co.il/server/zipfiles-images',{folder: folder, files: files, zipname: zipname}, config).then((response) => {
        console.log(response.data);
        var headers = response.headers;
       var blob = new Blob([response.data],{type:headers['content-type']});
       var link = document.createElement('a');
       link.href = window.URL.createObjectURL(blob);
       link.download = zipname;
       link.click();
       link.remove()
       this.setState({downloadPercenteges: 0})
       // pipe(response.data);
        // FileDownload(response.data, zipname);
      })
      .catch( (error) => {
        console.log(error);
      });
    }
     // var win = window.open('https://py.wesnapp.co.il/server/zipfiles?path=' + lineOfFiles + '&zipname=' + zipname, '_blank');
  }

  downloadZipFileByType = (type, zipname) => {
    var array = []
    if (type == "Raws") {
      array = this.state.images1Exposures.originals
    }
    if (type == "3 Exposures") {
      array = this.state.images3Exposures.originals
    }
    if (type == "4 Exposures") {
      array = this.state.images4Exposures.originals
    }
    if (type == "5 Exposures") {
      array = this.state.images5Exposures.originals
    }
    if (type == "6 Exposures") {
      array = this.state.images6Exposures.originals
    }
    if (type == "7 Exposures") {
      array = this.state.images7Exposures.originals
    }
    if (type == "8 Exposures") {
      array = this.state.images8Exposures.originals
    }
    if (type == "9 Exposures") {
      array = this.state.images9Exposures.originals
    }
    console.log(array);
    var lineOfFiles = ''
    for (var i = 0; i < array.length; i++) {
      var path = ''
      console.log(array[i]);
      path = array[i].replace('https://s3.amazonaws.com/wesnapp-development/', '')
      path.replace('/','-')
      if (lineOfFiles == '') {
        lineOfFiles = path
      } else {
        lineOfFiles = lineOfFiles + ',' + path
      }
    }
     // var win = window.open('https://py.wesnapp.co.il/server/zipfiles?path=' + lineOfFiles + '&zipname=' + zipname, '_blank');
     this.setState({downloadPercenteges: 1, prepearingImages: true})
     var config = {
       responseType: 'blob',
       onDownloadProgress: this.uploadDownloadProgress()
     };
     var filesOld = lineOfFiles.split(',')
     var files = []
     var folder = filesOld[0].split("/" + filesOld[0].split('/')[filesOld[0].split('/').length - 1])[0]
     for (var j = 0; j < filesOld.length; j++) {
       files.push(filesOld[j].split(folder + '/')[1])
     }


     console.log('folder');
     console.log(folder);
     console.log(files);
     axios.post('https://py.wesnapp.co.il/server/zipfiles-images',{folder: folder, files: files, zipname: zipname}, config).then((response) => {
       console.log(response.data);
       var headers = response.headers;
      var blob = new Blob([response.data],{type:headers['content-type']});
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = zipname;
      link.click();
      link.remove()
      this.setState({downloadPercenteges: 0})
      // pipe(response.data);
       // FileDownload(response.data, zipname);
     })
     .catch( (error) => {
       console.log(error);
     });
  }

  getDateAndTime = async (dateAndTime) => {
    var dateObj = new Date(dateAndTime);
    var stringToReturn = ''
    stringToReturn += dateObj.getDate() + "/"
    stringToReturn += (dateObj.getMonth() + 1) + "/"
    stringToReturn += dateObj.getFullYear() + " "
    var hour = dateObj.getHours()
    if (String(hour).length < 2) {
      hour = "0" + hour
    }
    var min = dateObj.getMinutes()
    if (String(min).length < 2) {
      min = "0" + min
    }
    stringToReturn += hour + ":"
    stringToReturn += min
    this.setState({dateAndTime: stringToReturn})
  }

  getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  getAngelTouchImages = () => {
    console.log("GETTING ANGLE IMAGES");
    axios.get(GET_ANGEL_IMAGES + this.state.requestId,{}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      console.log(response);
        this.setState({loadingATImages: false})
        var angelTouchImagesOriginals = {}

        var urls_infused = []
        var urls_lc = []
        var urls_wb = []
        var urls_wb_lab = []

        var urls_infused_originals = []
        var urls_lc_originals = []
        var urls_wb_originals = []
        var urls_wb_lab_originals = []

        var atImagesForGalleryInfuesd = []
        var atImagesForGalleryWB = []
        var atImagesForGalleryLC = []
        var atImagesForGalleryWB_LAB = []

        for (var i = 0; i < response.data.images_infused.compressed.length; i++) {

          urls_infused_originals.push(response.data.images_infused.originals[i])
          urls_lc_originals.push(response.data.images_lc.originals[i])
          urls_wb_originals.push(response.data.images_wb.originals[i])
          if (response.data.images_wb_lab.originals.length == response.data.images_infused.originals.length) {
            urls_wb_lab_originals.push(response.data.images_wb_lab.originals[i])
          }

          urls_infused.push(response.data.images_infused.compressed[i])
          urls_lc.push(response.data.images_lc.compressed[i])
          urls_wb.push(response.data.images_wb.compressed[i])
          if (response.data.images_wb_lab.compressed.length == response.data.images_infused.compressed.length) {
            urls_wb_lab.push(response.data.images_wb_lab.compressed[i])
          }

          atImagesForGalleryInfuesd.push({
            src: response.data.images_infused.compressed[i],
            thumbnail: response.data.images_infused.compressed[i],
            thumbnailWidth: 320,
            thumbnailHeight: 174,
            caption: "Image " + String(i)
          })

          atImagesForGalleryLC.push({
            src: response.data.images_lc.compressed[i],
            thumbnail: response.data.images_lc.compressed[i],
            thumbnailWidth: 320,
            thumbnailHeight: 174,
            caption: "Image " + String(i)
          })

          atImagesForGalleryWB.push({
            src: response.data.images_wb.compressed[i],
            thumbnail: response.data.images_wb.compressed[i],
            thumbnailWidth: 320,
            thumbnailHeight: 174,
            caption: "Image " + String(i)
          })

          if (response.data.images_wb_lab.compressed.length == response.data.images_infused.compressed.length) {
              atImagesForGalleryWB_LAB.push({
                src: response.data.images_wb_lab.compressed[i],
                thumbnail: response.data.images_wb_lab.compressed[i],
                thumbnailWidth: 320,
                thumbnailHeight: 174,
                caption: "Image " + String(i)
              })
          }

        }

        angelTouchImagesOriginals["wb"] = urls_wb_originals
        angelTouchImagesOriginals["wb_lab"] = urls_wb_lab_originals
        angelTouchImagesOriginals["infused"] = urls_infused_originals
        angelTouchImagesOriginals["lc"] = urls_lc_originals

        console.log("ANGEL IMAGES");
        console.log(urls_wb);
        this.setState({angelTouchImagesOriginals: angelTouchImagesOriginals, angelTouchImages: urls_infused, atImagesForGalleryLC: atImagesForGalleryLC, atImagesForGalleryWB: atImagesForGalleryWB , atImagesForGalleryWB_LAB: atImagesForGalleryWB_LAB, atImagesForGallery: atImagesForGalleryInfuesd, atImagesForGalleryInfuesd: atImagesForGalleryInfuesd, urls_infused: urls_infused, urls_lc: urls_lc, urls_wb: urls_wb, urls_wb_lab: urls_wb_lab})
      })
    .catch( (error) => {
      this.setState({loadingATImages: false})
      console.log(error);
    });
  }

  getStyleForImageInUploadRaws = (item) => {
    if (item.percentCompleted == 100) {
      return {borderStyle: 'solid', borderWidth: 4, display: 'inline-block', position: "relative", marginLeft: 15, marginBottom: 15, width: 'calc(33.33% - 15px)'}
    } else {
      return {borderColor: '#544cf8', borderStyle: 'solid', borderWidth: 4, display: 'inline-block', position: "relative", marginLeft: 15, marginBottom: 15, width: 'calc(33.33% - 15px)'}
    }
  }

  handleOpen = (whichOne) => {
    if (whichOne == 'X') {
      this.setState({openAlert: true})
    } else {
      this.setState({openSuccess: true})
    }
  }

  handleClose = (whichOne) => {
    if (whichOne == 'X') {
      this.setState({openAlert: false})
    } else {
      this.setState({openSuccess: false})
    }
  }

  getStationStyle = (i) => {
    if (this.state.station == i) {
      return {color: 'white', marginBottom: 20, height: 50, borderRadius: 25, background: "linear-gradient(to right , rgb(94,44,237), rgb(94,44,237))", boxShadow: "0px 10px 15px -10px rgba(0,0,0,0.0)"}
    } else {
      return {color: 'rgb(94,44,237)', marginBottom: 20, height: 50, borderRadius: 25, background: "linear-gradient(to right , #EEF2F9, #EEF2F9)", boxShadow: "0px 10px 15px -10px rgba(0,0,0,0.0)"}
    }
  }

  getUploadStationStyle = (i) => {
    if (i == '+') {
      return {color: '#544cf8', fontSize: 21, borderColor: '#544cf8', borderWidth: '2px', marginBottom: 20, height: 50, borderRadius: 25, backgroundColor: 'rgb(249,250,252)', borderStyle: 'dashed', boxShadow: "0px 10px 15px -10px rgba(0,0,0,0.00)"}
    }
    if (this.state.uploadStation == i) {
      return {color: 'white', marginBottom: 20, height: 50, borderRadius: 25, background: "linear-gradient(to right , rgb(94,44,237), rgb(94,44,237))", boxShadow: "0px 10px 15px -10px rgba(0,0,0,0.0)"}
    } else {
      return {color: 'rgb(94,44,237)', marginBottom: 20, height: 50, borderRadius: 25, background: "linear-gradient(to right , #EEF2F9, #EEF2F9)", boxShadow: "0px 10px 15px -10px rgba(0,0,0,0.0)"}
    }
  }

  getStyleForComponent = (station) => {
    if (this.state.uploadStation == station) {
      return {display: 'block'}
    } else {
      return {display: 'none'}
    }
  }

  getName = (url) => {
    var arr = url.split('/')
    var last = arr[arr.length - 1]
    return last.replace('_reduced_watermarked', '')
  }

  getCoordinates = async (address) => {
    try {
      var link = "https://maps.googleapis.com/maps/api/geocode/json?address=" + encodeURI(address) + "&key=AIzaSyDJ7KNKLQOtQqlx871XHHU9EcZRt2K4qso"
      const response = await axios.get(link);
      console.log(response.data.results[0].geometry.location);
      this.setState({lat: response.data.results[0].geometry.location.lat, lng: response.data.results[0].geometry.location.lng})
    } catch (err) {
        console.error(err);
    }
  }

  getNameFromUrl = (item) => {
    if (item != undefined) {
      var arr = item.split('/')
      var last = arr[arr.length - 1]
      return last.replace('_reduced', '')
    }
    else return ''
  }


  handleClickExposures = (event) => {
    console.log(event.currentTarget);
  }

  handleOpenExposures = () => {
    this.setState({openMenu: true})
  }

  handleCloseExposures = () => {
    this.setState({openMenu: false})
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root}>
      <CommentsDialog main={this}/>
      {this.state.isDeleting == true && (
        <div className="loading"><CircularProgress style={{marginTop: 'calc(40vh - 40px)'}}/><div><div style={{fontFamily: 'arial', fontSize: 25, color: 'black', marginTop: 20, fontWeight: 'bold'}}>Deleting Image</div></div></div>
      )}
      {this.state.angelsIsOpen && (
        <Lightbox
          mainSrc={this.state.angelTouchImages[this.state.angelsPhotoIndex]}
          nextSrc={this.state.angelTouchImages[(this.state.angelsPhotoIndex + 1) % this.state.angelTouchImages.length]}
          prevSrc={this.state.angelTouchImages[(this.state.angelsPhotoIndex + this.state.angelTouchImages.length - 1) % this.state.angelTouchImages.length]}
          onCloseRequest={() => this.setState({ angelsIsOpen: false })}
          onMovePrevRequest={() =>
            this.setState({
              angelsPhotoIndex: (this.state.angelsPhotoIndex + this.state.angelTouchImages.length - 1) % this.state.angelTouchImages.length,
            })
          }
          onMoveNextRequest={() =>
            this.setState({
              angelsPhotoIndex: (this.state.angelsPhotoIndex + 1) % this.state.angelTouchImages.length,
            })
          }
        />
      )}
      {this.state.editedIsOpen && (
        <Lightbox
          mainSrc={this.state.editedImages.imagesForGallery[this.state.editedPhotoIndex].src}
          nextSrc={this.state.editedImages.imagesForGallery[(this.state.editedPhotoIndex + 1) % this.state.editedImages.imagesForGallery.length].src}
          prevSrc={this.state.editedImages.imagesForGallery[(this.state.editedPhotoIndex + this.state.editedImages.imagesForGallery.length - 1) % this.state.editedImages.imagesForGallery.length].src}
          onCloseRequest={() => this.setState({ editedIsOpen: false })}
          onMovePrevRequest={() =>
            this.setState({
              editedPhotoIndex: (this.state.editedPhotoIndex + this.state.editedImages.imagesForGallery.length - 1) % this.state.editedImages.imagesForGallery.length,
            })
          }
          onMoveNextRequest={() =>
            this.setState({
              editedPhotoIndex: (this.state.editedPhotoIndex + 1) % this.state.editedImages.imagesForGallery.length,
            })
          }
        />
      )}
        {this.state.loading && (
          <div className="loading"><CircularProgress style={{marginTop: 'calc(40vh - 40px)'}}/><div><div style={{fontFamily: 'arial', fontSize: 25, color: 'black', marginTop: 20, fontWeight: 'bold'}}>Loading...</div></div></div>
        )}
        {this.state.deleting && (
          <div className="loading"><CircularProgress style={{marginTop: 'calc(40vh - 40px)'}}/><div><div style={{fontFamily: 'arial', fontSize: 25, color: 'black', marginTop: 20, fontWeight: 'bold'}}>Deleting...</div></div></div>
        )}
        <Dialog
        open={this.state.openSuccess}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleClose('V')}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
        <center>
          <div>
            <img style={{width: 80, height: 80}} src={successIcon} alt="..." />
          </div>
        </center>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" style={{fontSize: 22}}>
            All images successfully uploaded
          </DialogContentText>
        </DialogContent>
        <DialogActions>
      {
          // <Button onClick={this.handleClose} color="primary">
          //   Close
          // </Button>
        }
        </DialogActions>
      </Dialog>
      <Dialog
      open={this.state.openAlert}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => this.handleClose('X')}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
      <center>
        <div>
          <img style={{width: 80, height: 80}} src={alertIcon} alt="..." />
        </div>
      </center>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description" style={{fontSize: 22}}>
          Please make sure you have not missed any photos
        </DialogContentText>
      </DialogContent>
      <DialogActions>
      </DialogActions>
    </Dialog>
        <Grid container spacing={24} dir="ltr">
          <Grid item xs={0} sm={0} md={1}>
          </Grid>
          <Grid item xs={12} sm={12} md={10}>
              <div>
              <Grid container spacing={24} dir="ltr" style={{marginTop: 35}}>
                <Grid item xs={12} sm={12} md={12}>
                <div style={{fontSize: 24, color: 'rgb(40,40,53)', textAlign: 'left', fontFamily: 'arial', marginTop: 0, marginBottom: 30}}>
                  <b>Photo Shoot</b> #{this.state.requestName}
                </div>
                <div style={{marginTop: 0}}>
                {
                  // <div style={{marginRight: 30, display: 'inline-block', verticalAlign: 'top', textAlign: 'left', backgroundColor: 'rgb(246,247,250)', width: 'calc(70% - 30px)',  height: 200, borderRadius: 30}}>
                  // </div>
                }
                  <div style={{width: '100%', display: 'inline-block', verticalAlign: 'top', borderRadius: 30, height: 200, position: 'relative'}}>
                  <Map google={window.google} lat={this.state.lat} lng={this.state.lng}/>
                  </div>
                </div>
                <div style={{marginTop: 30, textAlign: 'left'}}></div>
              <div style={{color: 'rgb(129,139,166)', width: '100%'}}>
                <Grid container spacing={24} dir="ltr" justify="center">
                  <Grid item xs={12} sm={12} md={6} xl={3}>
                    <div style={{fontFamily: 'arial', display: 'inline-block', width: '100%', height: 100, backgroundColor: 'rgb(239,242,249)', borderRadius: 20}}>
                      <div style={{display: 'inline-block', width: 50, height: 50, backgroundColor: 'white', marginTop: 25, marginLeft: 15, float: 'left', borderRadius: 15}}>
                        <PersonIcon size="25" style={{color: 'rgb(94,44,237)', marginTop: 12.5}}/>
                      </div>
                      <div style={{display: 'inline-block', textAlign: 'left', height: 50, marginTop: 20, marginLeft: 10, float: 'left', borderRadius: 15, fontSize: 16}}>
                        <div style={{marginTop: 4, maxWidth: '80%'}}><b>Photographer</b></div>
                        <div style={{marginTop: 8}}>
                          {this.state.photographerName}
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} xl={3}>
                    <div style={{fontFamily: 'arial', display: 'inline-block', width: '100%', height: 100, backgroundColor: 'rgb(239,242,249)', borderRadius: 20}}>
                      <div style={{display: 'inline-block', width: 50, height: 50, backgroundColor: 'white', marginTop: 25, marginLeft: 15, float: 'left', borderRadius: 15}}>
                        <PhotoCameraIcon size="25" style={{color: 'rgb(94,44,237)', marginTop: 12.5}}/>
                      </div>
                      <div style={{display: 'inline-block', textAlign: 'left', height: 50, marginTop: 20, marginLeft: 10, float: 'left', borderRadius: 15, fontSize: 16}}>
                        <div style={{marginTop: 4}}><b>Product</b></div>
                        <div style={{marginTop: 8}}>
                          {this.state.productName}
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} xl={3}>
                    <div style={{fontFamily: 'arial', display: 'inline-block', width: '100%', height: 100, backgroundColor: 'rgb(239,242,249)', borderRadius: 20}}>
                      <div style={{display: 'inline-block', width: 50, height: 50, backgroundColor: 'white', marginTop: 25, marginLeft: 15, float: 'left', borderRadius: 15}}>
                        <LocationCityIcon size="25" style={{color: 'rgb(94,44,237)', marginTop: 12.5}}/>
                      </div>
                      <div style={{display: 'inline-block', textAlign: 'left', maxWidth: 'calc(100% - 85px)', marginLeft: 10, height: 50, marginTop: 20, float: 'left', borderRadius: 15, fontSize: 16}}>
                        <div style={{marginTop: 4}}><b>Location</b></div>
                        <div style={{marginTop: 8}}>
                          {this.state.shootingLocation}
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} xl={3}>
                    <div style={{fontFamily: 'arial', display: 'inline-block', width: '100%', height: 100, backgroundColor: 'rgb(239,242,249)', borderRadius: 20}}>
                      <div style={{display: 'inline-block', width: 50, height: 50, backgroundColor: 'white', marginTop: 25, marginLeft: 15, float: 'left', borderRadius: 15}}>
                        <CalendarTodayIcon size="25" style={{color: 'rgb(94,44,237)', marginTop: 12.5}}/>
                      </div>
                      <div style={{display: 'inline-block', textAlign: 'left', height: 50, marginTop: 20, marginLeft: 10, float: 'left', borderRadius: 15, fontSize: 16}}>
                        <div style={{marginTop: 4}}><b>Date & Time</b></div>
                        <div style={{marginTop: 8}}>
                          {this.state.dateAndTime}
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
                </Grid>
                <div style={{marginBottom: 20}}></div>
                <Grid item xs={12} sm={12} md={12}>
                  <div style={{fontSize: 24, color: 'rgb(40,40,53)', textAlign: 'left', fontFamily: 'arial', marginTop: 0, marginBottom: 10}}>
                    {(this.props.main.props.main.state.uploadAndLastUpload == 'Upload') && (<div><b>Upload</b> Originals</div>)}
                    {(this.props.main.props.main.state.uploadAndLastUpload == 'LastUpload') && (<div><b>Last</b> Upload</div>)}
                    {(this.props.main.props.main.state.uploadAndLastUpload == 'AngleTouch') && (<div><b>Angel</b> Touch</div>)}
                    {(this.props.main.props.main.state.uploadAndLastUpload == 'UploadEdited') && (<div><b>Upload</b> Edited</div>)}
                    {(this.props.main.props.main.state.uploadAndLastUpload == 'EditedImages') && (<div><b>Edited</b> Images</div>)}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                <div style={{width: '100%', marginBottom: 0}}>
                  {(this.state.downloadPercenteges != 100 && this.state.downloadPercenteges != 0) && (
                    <div style={{width: '100%', marginTop: 0, textAlign: 'left', marginBottom: 10, height: 40}}>
                      <div style={{marginTop: 0}}>
                        {this.state.prepearingImages ? (
                          <div style={{width: '100%', textAlign: 'left', color: 'black', marginBottom: 10}}>Prepearing images.. Download will start in few seconds</div>
                        ) : (
                          <div style={{width: '100%', textAlign: 'left', color: 'black', marginBottom: 10}}>Download in progress - {this.state.downloadPercenteges}%</div>
                        )}
                        <BorderLinearProgress variant="determinate" value={this.state.downloadPercenteges} />
                      </div>
                    </div>
                  )}
                  </div>
                </Grid>
                  <Grid item xs={3} sm={3} md={3} style={(this.props.main.props.main.state.uploadAndLastUpload == 'Upload') ? {} : { display: 'none' }}>
                    <div style={{width: '100%'}}>
                      <div style={{display: 'inline-block', fontWeight: 'bold', fontFamily: 'arial', fontSize: 20, textAlign: 'left', width: '100%', color: '#818BA6', marginBottom: 19}}>Stations</div>
                      <div style={{ width: '100%', marginLeft: -2.5, maxWidth: 300, marginTop: 10}}>
                        <Button variant="contained" type="submit" elevation={0} fullWidth style={this.getUploadStationStyle('Raws')} onClick={ () => this.setState({uploadStation: 'Raws'})}>
                          Single
                        </Button>
                        <Button variant="contained" type="submit" elevation={0} fullWidth style={this.getUploadStationStyle('3 Exposures')} onClick={ () => this.setState({uploadStation: '3 Exposures'})}>
                          3 Exposures
                        </Button>
                        { this.state.exposures4 && (
                          <Button variant="contained" type="submit" elevation={0} fullWidth style={this.getUploadStationStyle('4 Exposures')} onClick={ () => this.setState({uploadStation: '4 Exposures'})}>
                            4 Exposures
                          </Button>
                        )}
                        { this.state.exposures5 && (
                        <Button variant="contained" type="submit" elevation={0} fullWidth style={this.getUploadStationStyle('5 Exposures')} onClick={ () => this.setState({uploadStation: '5 Exposures'})}>
                          5 Exposures
                        </Button>
                        )}
                        { this.state.exposures6 && (
                        <Button variant="contained" type="submit" elevation={0} fullWidth style={this.getUploadStationStyle('6 Exposures')} onClick={ () => this.setState({uploadStation: '6 Exposures'})}>
                          6 Exposures
                        </Button>
                        )}
                        { this.state.exposures7 && (
                        <Button variant="contained" type="submit" elevation={0} fullWidth style={this.getUploadStationStyle('7 Exposures')} onClick={ () => this.setState({uploadStation: '7 Exposures'})}>
                          7 Exposures
                        </Button>
                        )}
                        { this.state.exposures8 && (
                        <Button variant="contained" type="submit" elevation={0} fullWidth style={this.getUploadStationStyle('8 Exposures')} onClick={ () => this.setState({uploadStation: '8 Exposures'})}>
                          8 Exposures
                        </Button>
                        )}
                        { this.state.exposures9 && (
                        <Button variant="contained" type="submit" elevation={0} fullWidth style={this.getUploadStationStyle('9 Exposures')} onClick={ () => this.setState({uploadStation: '9 Exposures'})}>
                          9 Exposures
                        </Button>
                        )}
                        <ExposuresMenu main={this}/>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={9} sm={9} md={9} style={(this.props.main.props.main.state.uploadAndLastUpload == 'Upload') ? {} : { display: 'none' }}>
                    <div>
                      <div style={{display: 'inline-block', fontWeight: 'bold', width: '50%',fontFamily: 'arial', fontSize: 20, textAlign: 'left', marginBottom: 5, color: '#818BA6', marginTop: -16}}>{this.state.uploadStation}</div>
                      <div style={{display: 'inline-block', fontWeight: 'bold', width: '50%', fontFamily: 'arial', fontSize: 20, textAlign: 'right', marginBottom: 5, color: '#818BA6', marginTop: -16}}>
                        <Button variant="contained" type="submit" elevation={0} style={{color: 'white', marginBottom: 20, height: 40, borderRadius: 20, background: "linear-gradient(to right , rgb(94,44,237), rgb(94,44,237))", boxShadow: "0px 10px 15px -10px rgba(0,0,0,0.0)"}} onClick={ () => this.setState({commentDialogOpen: true})}>
                          Add comments
                        </Button>
                      </div>
                    </div>
                    <div style={this.state.uploadStation == '3 Exposures' ? {} : { display: 'none' }}>
                      <Upload3Exosures main={this} requestId={this.state.requestId} galleryOrLinesUpload={this.state.galleryOrLinesUpload}/>
                    </div>
                    <div style={this.state.uploadStation == '4 Exposures' ? {} : { display: 'none' }}>
                      <Upload4Exosures main={this} requestId={this.state.requestId} galleryOrLinesUpload={this.state.galleryOrLinesUpload}/>
                    </div>
                    <div style={this.state.uploadStation == '5 Exposures' ? {} : { display: 'none' }}>
                      <Upload5Exosures main={this} requestId={this.state.requestId} galleryOrLinesUpload={this.state.galleryOrLinesUpload}/>
                    </div>
                    <div style={this.state.uploadStation == '6 Exposures' ? {} : { display: 'none' }}>
                      <Upload6Exosures main={this} requestId={this.state.requestId} galleryOrLinesUpload={this.state.galleryOrLinesUpload}/>
                    </div>
                    <div style={this.state.uploadStation == '7 Exposures' ? {} : { display: 'none' }}>
                      <Upload7Exosures main={this} requestId={this.state.requestId} galleryOrLinesUpload={this.state.galleryOrLinesUpload}/>
                    </div>
                    <div style={this.state.uploadStation == '8 Exposures' ? {} : { display: 'none' }}>
                      <Upload8Exosures main={this} requestId={this.state.requestId} galleryOrLinesUpload={this.state.galleryOrLinesUpload}/>
                    </div>
                    <div style={this.state.uploadStation == '9 Exposures' ? {} : { display: 'none' }}>
                      <Upload9Exosures main={this} requestId={this.state.requestId} galleryOrLinesUpload={this.state.galleryOrLinesUpload}/>
                    </div>
                    <div style={this.state.uploadStation == 'Raws' ? {} : { display: 'none' }}>
                      <Upload1Exosures main={this} requestId={this.state.requestId} galleryOrLinesUpload={this.state.galleryOrLinesUpload}/>
                    </div>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3}  style={(this.props.main.props.main.state.uploadAndLastUpload == 'LastUpload') ? {} : { display: 'none' }}>
                    <div style={{width: '100%'}}>
                      <div style={{display: 'inline-block', fontWeight: 'bold', fontFamily: 'arial', fontSize: 20, textAlign: 'left', width: '100%', color: '#818BA6', marginBottom: 19}}>Stations</div>
                      <div style={{ width: '100%', marginLeft: -2.5, maxWidth: 300}}>
                        {this.state.images1Exposures.compressed.length > 0 && (
                        <Button variant="contained" type="submit" elevation={0} fullWidth style={this.getUploadStationStyle('Raws')} onClick={ () => this.setState({uploadStation: 'Raws'})}>
                          Single
                        </Button>
                        )}
                        {this.state.images3Exposures.compressed.length > 0 && (
                        <Button variant="contained" type="submit" elevation={0} fullWidth style={this.getUploadStationStyle('3 Exposures')} onClick={ () => this.setState({uploadStation: '3 Exposures'})}>
                          3 Exposures
                        </Button>
                        )}
                        {this.state.images4Exposures.compressed.length > 0 && (
                        <Button variant="contained" type="submit" elevation={0} fullWidth style={this.getUploadStationStyle('4 Exposures')} onClick={ () => this.setState({uploadStation: '4 Exposures'})}>
                          4 Exposures
                        </Button>
                        )}
                        {this.state.images5Exposures.compressed.length > 0 && (
                        <Button variant="contained" type="submit" elevation={0} fullWidth style={this.getUploadStationStyle('5 Exposures')} onClick={ () => this.setState({uploadStation: '5 Exposures'})}>
                          5 Exposures
                        </Button>
                        )}
                        {this.state.images6Exposures.compressed.length > 0 && (
                        <Button variant="contained" type="submit" elevation={0} fullWidth style={this.getUploadStationStyle('6 Exposures')} onClick={ () => this.setState({uploadStation: '6 Exposures'})}>
                          6 Exposures
                        </Button>
                        )}
                        {this.state.images7Exposures.compressed.length > 0 && (
                        <Button variant="contained" type="submit" elevation={0} fullWidth style={this.getUploadStationStyle('7 Exposures')} onClick={ () => this.setState({uploadStation: '7 Exposures'})}>
                          7 Exposures
                        </Button>
                        )}
                        {this.state.images8Exposures.compressed.length > 0 && (
                        <Button variant="contained" type="submit" elevation={0} fullWidth style={this.getUploadStationStyle('8 Exposures')} onClick={ () => this.setState({uploadStation: '8 Exposures'})}>
                          8 Exposures
                        </Button>
                        )}
                        {this.state.images9Exposures.compressed.length > 0 && (
                        <Button variant="contained" type="submit" elevation={0} fullWidth style={this.getUploadStationStyle('9 Exposures')} onClick={ () => this.setState({uploadStation: '9 Exposures'})}>
                          9 Exposures
                        </Button>
                        )}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={9} sm={9} md={9} style={(this.props.main.props.main.state.uploadAndLastUpload == 'LastUpload') ? {} : { display: 'none' }}>
                    {(this.state.images1Exposures.compressed.length > 0 || this.state.images3Exposures.compressed.length > 0 || this.state.images4Exposures.compressed.length > 0 || this.state.images5Exposures.compressed.length > 0 || this.state.images6Exposures.compressed.length > 0 || this.state.images7Exposures.compressed.length > 0 || this.state.images8Exposures.compressed.length > 0 || this.state.images9Exposures.compressed.length > 0) ? (
                      <div>
                        <div style={{display: 'inline-block', fontWeight: 'bold', width: '50%',fontFamily: 'arial', fontSize: 20, textAlign: 'left', marginBottom: 5, color: '#818BA6', marginTop: -10}}>{this.state.uploadStation}</div>
                        <div style={{display: 'inline-block', fontWeight: 'bold', width: '50%', fontFamily: 'arial', fontSize: 20, textAlign: 'right', marginBottom: 5, color: '#818BA6', marginTop: -10}}>
                        <Tooltip title={"Download Zip"} placement="top">
                          <FormControlLabel
                            control={
                                <div style={{display: 'inline-block', fontSize: 16, float: 'right', marginRight: 9}} className="buttonIcon">
                                  <img src={zipIcon} style={{objectFit: 'scale-down', height: 28}} alt="..." />
                                </div>
                            }
                            onClick={() => this.downloadZipFileByType(this.state.uploadStation, 'WeSnapp_' + this.state.requestName + '_' + this.state.uploadStation + '.zip')}
                            label="Download Zip"
                          />
                          </Tooltip>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={this.state.displayGallery}
                                onChange={this.handleChange('displayGallery')}
                                value="displayGallery"
                                color="primary"
                              />
                            }
                            label="Gallery View"
                          />
                        </div>
                      </div>
                    ): (
                      <div style={{marginTop: 10}}>
                        <center>
                          <CircularProgress style={{marginTop: 20}}/>
                          <div style={{marginTop: 20, fontSize: 20, fontFamily: 'arial', color: 'black'}}>Loading Images</div>
                        </center>
                      </div>
                    )}
                    <div style={(this.state.uploadStation == 'Raws' && this.state.images1Exposures.compressed.length > 0) ? {} : { display: 'none' }}>
                      <Uploaded1Exosures main={this} images={this.state.images1Exposures} requestId={this.state.requestId} galleryOrLinesUpload={this.state.galleryOrLinesUpload}/>
                    </div>
                    <div style={(this.state.uploadStation == '3 Exposures' && this.state.images3Exposures.compressed.length > 0) ? {} : { display: 'none' }}>
                      <Uploaded3Exosures main={this} images={this.state.images3Exposures} requestId={this.state.requestId} galleryOrLinesUpload={this.state.galleryOrLinesUpload}/>
                    </div>
                    <div style={(this.state.uploadStation == '4 Exposures' && this.state.images4Exposures.compressed.length > 0) ? {} : { display: 'none' }}>
                      <Uploaded4Exosures main={this} images={this.state.images4Exposures} requestId={this.state.requestId} galleryOrLinesUpload={this.state.galleryOrLinesUpload}/>
                    </div>
                    <div style={(this.state.uploadStation == '5 Exposures' && this.state.images5Exposures.compressed.length > 0) ? {} : { display: 'none' }}>
                      <Uploaded5Exosures main={this} images={this.state.images5Exposures} requestId={this.state.requestId} galleryOrLinesUpload={this.state.galleryOrLinesUpload}/>
                    </div>
                    <div style={(this.state.uploadStation == '6 Exposures' && this.state.images6Exposures.compressed.length > 0) ? {} : { display: 'none' }}>
                      <Uploaded6Exosures main={this} images={this.state.images6Exposures} requestId={this.state.requestId} galleryOrLinesUpload={this.state.galleryOrLinesUpload}/>
                    </div>
                    <div style={(this.state.uploadStation == '7 Exposures' && this.state.images7Exposures.compressed.length > 0) ? {} : { display: 'none' }}>
                      <Uploaded7Exosures main={this} images={this.state.images7Exposures} requestId={this.state.requestId} galleryOrLinesUpload={this.state.galleryOrLinesUpload}/>
                    </div>
                    <div style={(this.state.uploadStation == '8 Exposures' && this.state.images8Exposures.compressed.length > 0) ? {} : { display: 'none' }}>
                      <Uploaded8Exosures main={this} images={this.state.images8Exposures} requestId={this.state.requestId} galleryOrLinesUpload={this.state.galleryOrLinesUpload}/>
                    </div>
                    <div style={(this.state.uploadStation == '9 Exposures' && this.state.images9Exposures.compressed.length > 0) ? {} : { display: 'none' }}>
                      <Uploaded9Exosures main={this} images={this.state.images9Exposures} requestId={this.state.requestId} galleryOrLinesUpload={this.state.galleryOrLinesUpload}/>
                    </div>
                  </Grid>

              <Grid item xs={9} sm={9} md={3} style={(this.props.main.props.main.state.uploadAndLastUpload == 'AngleTouch') ? {} : { display: 'none' }}>
                <div style={{width: '100%'}}>
                  <div style={{display: 'inline-block', fontWeight: 'bold', fontFamily: 'arial', fontSize: 20, textAlign: 'left', width: '100%', color: '#818BA6', marginBottom: 19}}>Stations</div>
                  <div style={{marginTop: 0.5, width: '100%', marginLeft: -2.5, maxWidth: 300, marginTop: 15}}>
                    <Button variant="contained" type="submit" elevation={0} fullWidth style={this.getStationStyle('infused')} onClick={ () => this.setState({station: 'infused', atImagesForGallery: this.state.atImagesForGalleryInfuesd, angelTouchImages: this.state.urls_infused})}>
                      SPD
                    </Button>
                    <Button variant="contained" type="submit" elevation={0} fullWidth style={this.getStationStyle('lc')} onClick={ () => this.setState({station: 'lc', atImagesForGallery: this.state.atImagesForGalleryLC, angelTouchImages: this.state.urls_lc})}>
                      Lense Correction
                    </Button>
                    <Button variant="contained" type="submit" elevation={0} fullWidth style={this.getStationStyle('wb')} onClick={ () => this.setState({station: 'wb', atImagesForGallery: this.state.atImagesForGalleryWB, angelTouchImages: this.state.urls_wb})}>
                      White Balance
                    </Button>
                    <Button variant="contained" type="submit" elevation={0} fullWidth style={this.getStationStyle('wb_lab')} onClick={ () => this.setState({station: 'wb_lab', atImagesForGallery: this.state.atImagesForGalleryWB_LAB, angelTouchImages: this.state.urls_wb_lab})}>
                      White Balance Lab
                    </Button>
                  </div>
                </div>
              </Grid>
            <Grid item xs={9} sm={9} md={9} style={(this.props.main.props.main.state.uploadAndLastUpload == 'AngleTouch') ? {} : { display: 'none' }}>
              <div style={{width: '100%'}}>
                <div style={{display: 'inline-block', fontWeight: 'bold', width: '50%', fontFamily: 'arial', fontSize: 20, textAlign: 'left', color: '#818BA6', marginBottom: 19, marginTop: -13}}>Angel Touch ({this.state.angelTouchImages.length} Photos)</div>
                <div style={{display: 'inline-block', fontWeight: 'bold', width: '50%', fontFamily: 'arial', fontSize: 20, textAlign: 'right', color: '#818BA6', marginBottom: 19, marginTop: -15}}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.galleryOrLinesAT}
                      onChange={this.handleChange('galleryOrLinesAT')}
                      value="galleryOrLinesAT"
                      color="primary"
                    />
                  }
                  label="Gallery View"
                />
                <Tooltip title={"Download Zip"} placement="top">
                  <FormControlLabel
                    control={
                        <div style={{display: 'inline-block', fontSize: 16, float: 'right', marginRight: 9}} className="buttonIcon">
                          <img src={zipIcon} style={{objectFit: 'scale-down', height: 28}} alt="..." />
                        </div>
                    }
                    onClick={() => this.downloadZipFile(this.state.angelTouchImagesOriginals[this.state.station], 'WeSnapp_' + this.state.requestName + '_' + this.state.station + '.zip')}
                    label="Download Zip"
                  />
                  </Tooltip>
                </div>
                <div style={{marginTop: 0.5, width: '100%', marginLeft: -2.5}}>
                {this.state.loadingATImages && (
                  <center>
                    <CircularProgress style={{marginTop: 20}}/>
                    <div style={{marginTop: 20, fontSize: 20, fontFamily: 'arial', color: 'black'}}>Loading Images</div>
                  </center>
                )}
                { !this.state.galleryOrLinesAT ? (
                  <div>
                  {this.state.angelTouchImages.map((item, i) => (
                    <div>
                    <div style={{width: '100%', height: 50, marginBottom: 10, padding: 5,  alignItems: 'center', boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.09)'}} className="imageCell">
                    <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginRight: 9, marginLeft: 9}}>
                      <img style={{width: 18, height: 18, borderRadius: 9, backgroundColor: 'rgb(120,203,117)', marginTop: 10}} src={vIcon} alt="..." />
                    </div>
                    <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginRight: 5, marginTop: 9}}>{this.getNameFromUrl(item)}</div>
                    <div style={{display: 'inline-block', fontSize: 16, float: 'right', marginRight: 5}}>
                      <LazyLoad offset={100}>
                        <img src={item} style={{objectFit: 'scale-down', height: 40}} alt="..." />
                      </LazyLoad>
                    </div>
                    {
                    // <Tooltip title={"Delete"} placement="top">
                    // <div onClick={() => this.deleteBracketImage(i)} style={{display: 'inline-block', fontSize: 16, float: 'right', right: 0, marginRight: 9, marginTop: 8}} className="buttonIcon">
                    //   <img src={trashIcon} style={{objectFit: 'scale-down', height: 22, fill: 'red'}} alt="..." />
                    // </div>
                    // </Tooltip>
                    // <Tooltip title={"Download"} placement="top">
                    // <div style={{display: 'inline-block', fontSize: 16, float: 'right', marginRight: 9, marginTop: 8}} className="buttonIcon">
                    //   <img src={downloadTIcon} style={{objectFit: 'scale-down', height: 22}} alt="..." />
                    // </div>
                    // </Tooltip>
                  }
                    <Tooltip title={"Zoom In"} placement="top">
                    <div onClick={() => {this.setState({angelsIsOpen: true, angelsPhotoIndex: i})}} style={{display: 'inline-block', fontSize: 16, float: 'right', marginRight: 9, marginTop: 8, filter: 'opacity(1.0) drop-shadow(150 150 150 red)'}} className="buttonIcon">
                      <img src={zoomInIcon} style={{objectFit: 'scale-down', height: 22}} alt="..." />
                    </div>
                    </Tooltip>
                </div>
                </div>
                 ))}
                 </div>
               ) : (
                 <div>
                 <div style={{display: 'inline-block', width: 'calc(100% - 0px)', verticalAlign:'top'}}>
                   {this.state.atImagesForGallery.map((item, i) => (
                     <div style={{display: 'inline-block', width: '32%',marginBottom: 10, marginLeft: 10}}>
                       <div className="imgcontainer">
                         <img src={item.src} alt="Avatar" className="image" style={{width: '100%'}} />
                         <div className="middle">
                           <img src={zoomInIconBig} alt="Avatar" className="text" width="50px" height="50px" onClick={() => this.setState({angelsIsOpen: true, angelsPhotoIndex: i})}/>
                           <div className="text">{this.state.atImagesForGallery[i].src.split('/').slice(-1)[0].replace('_reduced', '')}</div>
                         </div>
                         <div className="boxinimage">
                           <div style={{marginTop: 47}}>
                             <div style={{display: 'inline-block', fontSize: '19px', marginTop: -8, textAlign: 'left', float: 'left', marginLeft: 8}}>Photo #{i+1}</div>
                             {
                             // <button style={{display: 'inline-block', cursor: 'pointer', height: 36, marginTop: -12, float: 'right', border: 'none', outline: 'none', backgroundColor: 'clear', background: 'none'}} onClick={() => this.props.main.deleteImage('3Exposures', i)} >
                             //   <Delete style={{width: 25, height: 25, color: 'white'}} />
                             // </button>
                             // <button style={{display: 'inline-block', cursor: 'pointer', height: 36, marginTop: -12, float: 'right', border: 'none', outline: 'none', backgroundColor: 'clear', background: 'none'}} onClick={ ()=> this.props.main.downloadOneImage(this.props.images.originals[i], this.props.images.originals[i].split('/').slice(-1)[0])}>
                             //   <CloudDownload style={{width: 25, height: 25, color: 'white'}} />
                             // </button>
                           }
                           </div>
                         </div>
                       </div>
                     </div>
                   ))}
                   </div>
                 </div>
               )}
                </div>
              </div>
            </Grid>
          <Grid item xs={12} sm={12} md={12} style={(this.props.main.props.main.state.uploadAndLastUpload == 'EditedImages') ? {} : { display: 'none' }}>
            <div style={{width: '100%'}}>
            <div style={{width: '100%', marginBottom: 0}}>
              <div style={{display: 'inline-block', float: 'left', fontWeight: 'bold', fontFamily: 'arial', fontSize: 20, textAlign: 'left', color: '#818BA6', marginBottom: 19}}>Edited Images ({this.state.editedImages.watermarked.length} Photos)</div>
              <div style={{display: 'inline-block',fontWeight: 'bold', float: 'right', fontFamily: 'arial', fontSize: 20, textAlign: 'right', color: '#818BA6', marginBottom: 19, marginTop: -15}}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.galleryOrLinesEdited}
                      onChange={this.handleChange('galleryOrLinesEdited')}
                      value="galleryOrLinesEdited"
                      color="primary"
                    />
                  }
                  label="Gallery View"
                />
              </div>
              <div style={{display: 'inline-block',fontWeight: 'bold', float: 'right', fontFamily: 'arial', fontSize: 20, textAlign: 'right', color: '#818BA6', marginBottom: 19, marginTop: -11}}>
              <Tooltip title={"Download Zip"} placement="top">
                <FormControlLabel
                  control={
                      <div style={{display: 'inline-block', fontSize: 16, float: 'right', marginRight: 9, marginTop: 8}} className="buttonIcon">
                        <img src={zipIcon} style={{objectFit: 'scale-down', height: 28}} alt="..." />
                      </div>
                  }
                  onClick={() => this.downloadZipFile(this.state.editedImages.originals, 'WeSnapp_' + this.state.requestName + '_edited.zip')}
                  label="Download Zip"
                />
                </Tooltip>
              </div>

              <div style={{display: 'inline-block',fontWeight: 'bold', float: 'right', fontFamily: 'arial', fontSize: 20, textAlign: 'right', color: '#818BA6', marginBottom: 19, marginTop: -11, marginRight: 12}}>
              <Tooltip title={"Download Watermarked"} placement="top">
                <FormControlLabel
                  control={
                      <div style={{display: 'inline-block', fontSize: 16, float: 'right', marginRight: 9, marginTop: 8}} className="buttonIcon">
                        <img src={zipIcon} style={{objectFit: 'scale-down', height: 28}} alt="..." />
                      </div>
                  }
                  onClick={() => this.downloadZipFile(this.state.editedImages.watermarked, 'WeSnapp_' + this.state.requestName + '_edited.zip')}
                  label="Download Watermarked"
                />
                </Tooltip>
              </div>
            </div>
            <div style={{height: 60}}>
            </div>
            { this.state.editedImages.loading && (
              <center>
                <CircularProgress style={{marginTop: 20}}/>
                <div style={{marginTop: 20, fontSize: 20, fontFamily: 'arial', color: 'black'}}>Loading Images</div>
              </center>
            )}
            {!this.state.regularOrWatermarked ? (
              <div style={{marginTop: 0.5, width: '100%', marginLeft: -2.5}}>
                {!this.state.galleryOrLinesEdited ? (
                  <div>
                    {this.state.editedImages.watermarked.map((item, i) => (
                      <div>
                      <div style={{width: '100%', height: 50, marginBottom: 10, padding: 5,  alignItems: 'center', boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.09)'}} className="imageCell">
                      <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginRight: 9, marginLeft: 9}}>
                        <img style={{width: 18, height: 18, borderRadius: 9, backgroundColor: 'rgb(120,203,117)', marginTop: 10}} src={vIcon} alt="..." />
                      </div>
                      <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginRight: 5, marginTop: 9}}>{this.getName(item)}</div>
                      <div style={{display: 'inline-block', fontSize: 16, float: 'right', marginRight: 5}}>
                        <LazyLoad offset={100}>
                          <img src={this.state.editedImages.thumbnail[i]} style={{objectFit: 'scale-down', height: 40}} alt="..." />
                        </LazyLoad>
                      </div>
                      <Tooltip title={"Delete"} placement="top">
                      <div onClick={() => this.deleteEditedImage(i)} style={{display: 'inline-block', fontSize: 16, float: 'right', right: 0, marginRight: 9, marginTop: 8}} className="buttonIcon">
                        <img src={trashIcon} style={{objectFit: 'scale-down', height: 22, fill: 'red'}} alt="..." />
                      </div>
                      </Tooltip>
                      <Tooltip title={"Download"} placement="top">
                      <div style={{display: 'inline-block', fontSize: 16, float: 'right', marginRight: 9, marginTop: 8}} className="buttonIcon">
                        <img src={downloadTIcon} style={{objectFit: 'scale-down', height: 22}} alt="..." />
                      </div>
                      </Tooltip>
                      <Tooltip title={"Zoom In"} placement="top">
                      <div onClick={() => {this.setState({editedIsOpen: true, editedPhotoIndex: i})}} style={{display: 'inline-block', fontSize: 16, float: 'right', marginRight: 9, marginTop: 8, filter: 'opacity(1.0) drop-shadow(150 150 150 red)'}} className="buttonIcon">
                        <img src={zoomInIcon} style={{objectFit: 'scale-down', height: 22}} alt="..." />
                      </div>
                      </Tooltip>
                  </div>
                  </div>
                   ))}
                  </div>
                ) : (
                  <div>
                    <div style={{display: 'inline-block', width: 'calc(100% - 0px)', verticalAlign:'top'}}>
                      {this.state.editedImages.imagesForGallery.map((item, i) => (
                        <div style={{display: 'inline-block', width: '24%',marginBottom: 10, marginLeft: 10}}>
                          <div className="imgcontainer">
                            <img src={item.src} alt="Avatar" className="image" style={{width: '100%'}} />
                            <div className="middle">
                              <img src={zoomInIconBig} alt="Avatar" className="text" width="30px" height="30px" onClick={() => this.setState({editedIsOpen: true, editedPhotoIndex: i})}/>
                              <div className="text">{this.state.editedImages.imagesForGallery[i].src.split('/').slice(-1)[0].replace('_reduced_watermarked', '')}</div>
                            </div>
                            <div className="boxinimage">
                              <div style={{marginTop: 47}}>
                                <div style={{display: 'inline-block', fontSize: '19px', marginTop: -8, textAlign: 'left', float: 'left', marginLeft: 8}}>Photo #{i+1}</div>
                                  <button style={{display: 'inline-block', cursor: 'pointer', height: 36, marginTop: -12, float: 'right', border: 'none', outline: 'none', backgroundColor: 'clear', background: 'none'}} onClick={() => this.deleteEditedImage(i)} >
                                    <Delete style={{width: 25, height: 25, color: 'white'}} />
                                  </button>
                                  <button style={{display: 'inline-block', cursor: 'pointer', height: 36, marginTop: -12, float: 'right', border: 'none', outline: 'none', backgroundColor: 'clear', background: 'none'}} onClick={ ()=> this.props.main.downloadOneImage(this.props.images.originals[i], this.props.images.originals[i].split('/').slice(-1)[0])}>
                                    <CloudDownload style={{width: 25, height: 25, color: 'white'}} />
                                  </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      </div>
                    </div>
                )}
              </div>
            ) : (
              <div style={{marginTop: 0.5, width: '100%', marginLeft: -2.5}}>
                {!this.state.galleryOrLinesEdited ? (
                  <div>
                    {this.state.editedImages.watermarked.map((item, i) => (
                      <div>
                      <div style={{width: '100%', height: 50, marginBottom: 10, padding: 5,  alignItems: 'center', boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.09)'}} className="imageCell">
                      <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginRight: 9, marginLeft: 9}}>
                        <img style={{width: 18, height: 18, borderRadius: 9, backgroundColor: 'rgb(120,203,117)', marginTop: 10}} src={vIcon} alt="..." />
                      </div>
                      <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginRight: 5, marginTop: 9}}>{this.getName(item)}</div>
                      <div style={{display: 'inline-block', fontSize: 16, float: 'right', marginRight: 5}}>
                        <LazyLoad offset={100}>
                          <img src={this.state.editedImages.thumbnail[i]} style={{objectFit: 'scale-down', height: 40}} alt="..." />
                        </LazyLoad>
                      </div>
                      <Tooltip title={"Delete"} placement="top">
                      <div onClick={() => this.deleteEditedImage(i)} style={{display: 'inline-block', fontSize: 16, float: 'right', right: 0, marginRight: 9, marginTop: 8}} className="buttonIcon">
                        <img src={trashIcon} style={{objectFit: 'scale-down', height: 22, fill: 'red'}} alt="..." />
                      </div>
                      </Tooltip>
                      <Tooltip title={"Download"} placement="top">
                      <div style={{display: 'inline-block', fontSize: 16, float: 'right', marginRight: 9, marginTop: 8}} className="buttonIcon">
                        <img src={downloadTIcon} style={{objectFit: 'scale-down', height: 22}} alt="..." />
                      </div>
                      </Tooltip>
                      <Tooltip title={"Zoom In"} placement="top">
                      <div onClick={() => {this.setState({watermarkIsOpen: true, watermarkPhotoIndex: i})}} style={{display: 'inline-block', fontSize: 16, float: 'right', marginRight: 9, marginTop: 8, filter: 'opacity(1.0) drop-shadow(150 150 150 red)'}} className="buttonIcon">
                        <img src={zoomInIcon} style={{objectFit: 'scale-down', height: 22}} alt="..." />
                      </div>
                      </Tooltip>
                  </div>
                  </div>
                   ))}
                  </div>
                ) : (

                    <div>
                    <div style={{display: 'inline-block', width: 'calc(100% - 0px)', verticalAlign:'top'}}>
                      {this.state.editedImages.imagesForGallery.map((item, i) => (
                        <div style={{display: 'inline-block', width: '24%',marginBottom: 10, marginLeft: 10}}>
                          <div className="imgcontainer">
                            <img src={item.src} alt="Avatar" className="image" style={{width: '100%'}} />
                            <div className="middle">
                              <img src={zoomInIconBig} alt="Avatar" className="text" width="80px" height="80px" onClick={() => this.setState({openGallery: true, index: i})}/>
                              <div className="text">{this.state.editedImages.imagesForGallery[i].src.split('/').slice(-1)[0].replace('_reduced_watermarked', '')}</div>
                            </div>
                          </div>
                          <div className="bar">
                            <div style={{display: 'inline-block', fontSize: '19px', marginTop: -3}}>Photo #{i+1}</div>
                            <button style={{display: 'inline-block', cursor: 'pointer', height: 36, marginTop: -8, color: 'white', float: 'right', border: 'none', outline: 'none'}} onClick={() => this.deleteEditedImage(i)} >
                              <img src={trashIcon} style={{objectFit: 'scale-down', height: 25}} alt="..." />
                            </button>
                            <button style={{display: 'inline-block', cursor: 'pointer', height: 36, marginTop: -8, color: 'white', float: 'right', border: 'none', outline: 'none'}} onClick={ ()=> this.props.main.downloadOneImage(this.props.images.originals[i], this.props.images.originals[i].split('/').slice(-1)[0])}>
                              <img src={downloadTIcon} style={{objectFit: 'scale-down', height: 25}} alt="..." />
                            </button>
                          </div>
                        </div>
                      ))}
                      </div>
                    </div>
                  )}
                  </div>

            )}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} style={(this.props.main.props.main.state.uploadAndLastUpload == 'UploadEdited') ? {} : { display: 'none' }}>
            <div>
            <div style={{fontWeight: 'bold', fontFamily: 'arial', fontSize: 20, textAlign: 'left', marginBottom: 19, color: '#818BA6'}}>Upload Edited Images</div>
              <EditedImages main={this} requestId={this.state.requestId} galleryOrLinesUpload={this.state.galleryOrLinesUpload}/>
            </div>
          </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <div style={{fontFamily: 'arial', fontWeight: 'bold', fontSize: 22, margin: 10}}></div>
            <Gallery
              images={this.state.urlRawsImages}
              enableImageSelection={false}
            />
        </Grid>
              </Grid>
              </div>

          </Grid>
          <Grid item xs={0} sm={0} md={1}>
          </Grid>
        </Grid>
      </div>
    );
  }
}

NewsPage.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(
    withSnackbar(NewsPage),
);
