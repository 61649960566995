import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import Checkbox from '@material-ui/core/Checkbox';
import Theme from '../../App.js';
// import RawOrBrackets from './RawOrBrackets.js'
function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const styles = theme => ({
  root: {
    backgroundColor: 'none',
    width: '100%'
  },
  checkbox: {
    color: 'rgb(120,195,17)',
    '&$checked': {
      color: 'rgb(120,195,17)',
    },
  },
  checked: {},
});

class FullWidthTabs extends React.Component {
  state = {
    value: 0,
    checked: false,
  };

  handleChange = (event, value) => {
    // this.props.main.setState({companyType: value})
    this.setState({ value });
    if (value == 0) {
      this.props.main.setState({uploadAndLastUpload: 'Upload'})
    } else if (value == 1) {
      this.props.main.get1Exposures()
      this.props.main.get3Exposures()
      this.props.main.get4Exposures()
      this.props.main.get5Exposures()
      this.props.main.get6Exposures()
      this.props.main.get7Exposures()
      this.props.main.get8Exposures()
      this.props.main.get9Exposures()
      this.props.main.setState({uploadAndLastUpload: 'LastUpload'})
    } else if (value == 2) {
      this.props.main.getAngelTouchImages()
      this.props.main.setState({uploadAndLastUpload: 'AngleTouch'})
    } else if (value == 3) {
      this.props.main.setState({uploadAndLastUpload: 'UploadEdited'})
    } else if (value == 4) {
      this.props.main.getEditedImages()
      this.props.main.setState({uploadAndLastUpload: 'EditedImages'})
    }
  };

  returnTabStyle = (index) => {
    if (this.state.value != index) {
      return {
        fontWeight: 'bold', height: '100%', width: '20%', textTransform: 'none', borderRadius: 25
      }
    } else {
      return {
        fontWeight: 'bold', height: '100%', width: '20%', textTransform: 'none', borderRadius: 25, color: 'white', background: "linear-gradient(to right , #544cf8, #814bfa)", marginLeft: 2
      }
    }
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root}>
        <center>
          <AppBar position="static" color="default" elevation={0} style={{height: 50, width: 850, borderRadius: 25, marginTop: 10, marginBottom: 10, border: '1px solid rgb(225,225,225)', boxShadow: '0px 0px 35px 0px rgba(0,0,0,0.1)', backgroundColor: 'white'}}>
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              indicatorColor="clear"
              textColor="primary"
            >
              <Tab style={this.returnTabStyle(0)} label="Upload Originals" />
              <Tab style={this.returnTabStyle(1)} label="Original Images" />
              <Tab style={this.returnTabStyle(2)} label="Angle Touch" />
              <Tab style={this.returnTabStyle(3)} label="Upload Edited" />
              <Tab style={this.returnTabStyle(4)} label="Edited Images" />
            </Tabs>
          </AppBar>
        </center>
      </div>
    );
  }
}

FullWidthTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(FullWidthTabs);
//
// import React from 'react';
// import PropTypes from 'prop-types';
// import { withStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
//
// const styles = {
//   root: {
//     flexGrow: 1,
//   },
// };
//
// class CenteredTabs extends React.Component {
//   state = {
//     value: 0,
//     checked: false,
//   };
//
//   handleChange = (event, value) => {
//     // this.props.main.setState({companyType: value})
//     this.setState({ value });
//     if (value == 0) {
//       this.props.main.setState({originalOrEdited: 'original'})
//     } else {
//       this.props.main.setState({originalOrEdited: 'edited'})
//     }
//   };
//
//   render() {
//     const { classes } = this.props;
//
//     return (
//       <Paper className={classes.root} elevation={0}>
//         <Tabs
//           value={this.state.value}
//           onChange={this.handleChange}
//           indicatorColor="primary"
//           textColor="primary"
//           centered
//         >
//           <Tab label="Original Images" />
//           <Tab label="Edited Images" />
//         </Tabs>
//       </Paper>
//     );
//   }
// }
//
// CenteredTabs.propTypes = {
//   classes: PropTypes.object.isRequired,
// };
//
// export default withStyles(styles)(CenteredTabs);
