// B1 - business and B2 - visitor
// L1 -
// E2 - Invester Visa
// E1 - International Trader
// National Interest
// Lottery

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import Cookies from 'universal-cookie';
import uuid from 'react-native-uuid';
import ImageUploader from 'react-images-upload';
import '../../../App.css';
// import RawOrBrackets from './RawOrBrackets.js';
import {DropzoneArea} from 'material-ui-dropzone';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
// import MuiDialogTitle from '@material-ui/core/DialogTitle';
// import MuiDialogContent from '@material-ui/core/DialogContent';
// import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Gallery from 'react-grid-gallery';
import Dropzone from "../Dropzone/Dropzone";
import Input from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// import GifLoader from 'react-gif-loader';
import Avatar from '@material-ui/core/Avatar';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Lightbox from 'react-image-lightbox';
// import ReactImageProcess from "react-image-process";
import { AuthController } from 'react-controllers'
// import LazyLoad from 'react-lazy-load';
import LazyLoad from 'react-lazyload'
import Tooltip from '@material-ui/core/Tooltip';
import 'react-image-lightbox/style.css';
import { withSnackbar } from 'notistack';

const BASE_URL = 'https://py.wesnapp.co.il/server/upload/';
const GET_RAW_IMAGES = 'https://py.wesnapp.co.il/server/get-compressed-raws/';
const GET_BRACKETS_IMAGES = 'https://py.wesnapp.co.il/server/get-compressed-brackets/';
const GET_EDITED_IMAGES = 'https://py.wesnapp.co.il/server/get-edited/';
const GET_EDITED_WATERMARKED_IMAGES = 'https://py.wesnapp.co.il/server/get-edited-watermarked/';
const GET_ANGEL_IMAGES = 'https://py.wesnapp.co.il/server/get-angel-images/';


const uploadIcon = require("../uploadIcon.png");
const downloadIcon = require("../downloadIcon.png");

// const loadingGif = require("./loadingGif.gif");
const vIcon = require("../vIcon.png");
const menuIcon = require("../menuIcon.png");
const xIcon = require("../xIcon.png");
const zoomIn = require("../zoomIn.png");

//https://www.iconfinder.com/icons/2561262/cloud_download_icon
const trashIcon = require("../trashTableIcon.png");
const zoomInIcon = require("../zoomInTableIcon.png");
const downloadTIcon = require("../downloadTableIcon.png");
const cameraIcon = require("../cameraTableIcon.png");

const dateIcon = require("../dateIcon.png");
const locationIcon = require("../locationIcon.png");
const userIcon = require("../userIcon.png");
const imageIcon = require("../imageIcon.png");

const alertIcon = require("../alertIcon.png");
const successIcon = require("../successIcon.png");
const zipIcon = require("../zipIcon.png");

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
  },
  margin: {
    margin: 10
  }
});

class Upload4Exposures extends React.Component {

  state = {
    urls: [],
    files: [],
    finishLoading: false,
    finishUploading: false,
    exposure: 9
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleChangeFiles(files){
    console.log(files);
    if (files.length > 0) {
      this.setState({finishLoading: false})
      var selectedFiles = this.state.files;
      var counter = 0
      for (var i = 0; i < files.length; i++) {
        var reader = new FileReader();
        var file = files[i]
        reader.onload = e => {
          selectedFiles.push({data: e.target.result, file: files[counter], size: files[counter].size, percentCompleted: 0, url: ''})
          counter += 1
          if (counter == files.length) {
            if (counter % this.state.exposure != 0) {
              // this.handleOpen('X')
            }
            var sortedFiles = selectedFiles.sort(this.naturalCompare);
            this.setState({
              files: sortedFiles,
              finishUploading: false,
            }, () => {
              console.log("DONE");
              this.uploaderImages()
              this.setState({finishLoading: false})
            })
          }
        };
        if (!this.checkIfFileExisting(file, selectedFiles)) {
          reader.readAsDataURL(file);
        }
      }
    }
  }

  naturalCompare(a, b) {
    var ax = [], bx = [];
    a.file.name.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
    b.file.name.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });
    while(ax.length && bx.length) {
        var an = ax.shift();
        var bn = bx.shift();
        var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
        if(nn) return nn;
    }
    return ax.length - bx.length;
  }

  naturalCompareByUrl(a, b) {
    var ax = [], bx = [];
    a.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
    b.replace(/(\d+)|(\D+)/g, function(_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });
    while(ax.length && bx.length) {
        var an = ax.shift();
        var bn = bx.shift();
        var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
        if(nn) return nn;
    }
    return ax.length - bx.length;
  }

  checkIfFileExisting = (file, files) => {
    for (var i = 0; i < files.length; i++) {
      if (files[i].file.name == file.name) {
        return true
      }
    }
    return false
  }

  getDateAndTime = () => {
    var newdate = ""
    var event = new Date();
    var e = event.toLocaleString("IST", {timeZone: "Asia/Jerusalem"})
    var newdateForSalesforce = e
    var date = e.split(',')[0]
    var date_ = date.split('/')
    var month = date_[0]
    var day = date_[1]
    var year = date_[2]
    if (String(month).length < 2) {
      month = "0" + String(month)
    }
    if (String(day).length < 2) {
      day = "0" + String(day)
    }
    newdate = year + "-" + month + "-" + day;
    console.log(newdate)
    var time = e.split(',')[1]
    time = time.replace(" ", "")
    var times = time.split(":")
    var hour = times[0]
    console.log(times[2].includes('PM'))
    if (times[2].includes('PM')) {
      if (hour === "1") {
          hour = "13";
        }
        if (hour === "2") {
          hour = "14";
        }
        if (hour === "3") {
          hour = "15";
        }
        if (hour === "4") {
          hour = "16";
        }
        if (hour === "5") {
          hour = "17";
        }
        if (hour === "6") {
          hour = "18";
        }
        if (hour === "7") {
          hour = "19";
        }
        if (hour === "8") {
          hour = "20";
        }
        if (hour === "9") {
          hour = "21";
        }
        if (hour === "10") {
          hour = "22";
        }
        if (hour === "11") {
          hour = "23";
        }
        if (hour === "12") {
          hour = "12";
        }
    }
    var minutes = times[1]
    var seconds = times[2].replace("AM", "").replace("PM", "").replace(" ", "")
    var time_full = hour + ':' + minutes + ':' + seconds
    console.log(time_full)

    var newdateForSalesforce = newdate + "T" + time_full + "+0300"
    return newdateForSalesforce
  }

  uploaderImages = () => {
    var addExposure = this.props.main.state.uploadedExposures
    if (this.props.main.state.uploadedExposures == "") {
      addExposure = this.props.main.state.uploadedExposures + '9'
      this.props.main.setState({uploadedExposures: addExposure})
    } else {
      addExposure = this.props.main.state.uploadedExposures + ';9'
      this.props.main.setState({uploadedExposures: addExposure})
    }
    axios.post('https://wesnapp.co.il/api/request/update-request',{requestId: this.props.requestId, data: {Status__c: 'Images Uploaded', Original_Image_Upload_Date_Time__c: this.getDateAndTime(), Type_Of_Upload__c: addExposure}}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      console.log("@@@");
      console.log(response.data);
    })
    .catch( (error) => {
      console.log(error);
    });
    var counter = 0
    var files = this.state.files
    var images9Exposures = this.props.main.state.images9Exposures
    if (files.length % this.state.exposure != 0) {
      this.handleAlert()
    }
    if (files.length > 0) {
      while (counter < files.length) {
        var image = files[counter]
        if (image.percentCompleted != 100) {
          var config = {
            onUploadProgress: this.uploadProgress(counter)
          };
          counter += 1
          var data = new FormData();
          var imageName = ''
          var splited = image.file.name.split('.')
          imageName += splited[0]+ "(" + this.state.exposure + ")." + splited[1]
          data.append("image", image.file, imageName);
          axios.post(BASE_URL + this.props.requestId + "-originals-bracketing-9Exposures", data, config).then(response => {
            // console.log("IMAGE UPLOADED");
            // console.log(response.data);
            for (var i = 0; i < files.length; i++) {
              var x = response.data.thumbnailImageUrl.split('/')
              // console.log(x[x.length - 1]);
              // console.log(files[i].file.name);
              var fileName = ''
              var splited = files[i].file.name.split('.')
              fileName = splited[0] + "(" + this.state.exposure + ")." + splited[1]
              fileName.replace('_reduced_thumbnail', '')
              console.log(x[x.length - 1].replace('_reduced_thumbnail', ''));
              if (x[x.length - 1].replace('_reduced_thumbnail', '') == fileName) {
                files[i].url = response.data.thumbnailImageUrl
                images9Exposures.originals.push(response.data.imageUrl)
                images9Exposures.thumbnail.push(response.data.thumbnailImageUrl)
                images9Exposures.compressed.push(response.data.reducedImageUrl)
                break
              }
            }

            this.setState({files: files})

            var x = false
            for (var i = 0; i < files.length; i++) {
              if (files[i].url == "") {
                x = true
              }
            }

            // this.handleSuccess()

            if (!x) {
              this.handleSuccess()
              this.props.main.editImages('9')

            }

            var sortedFiles = images9Exposures.originals.sort(this.naturalCompareByUrl);

            // this.props.main.setState({images9Exposures: images9Exposures})

            this.props.main.get9Exposures()

          })
          console.log("FINISHED");
        } else {
          counter += 1
        }
      }
    } else {
        alert('All images are uploaded successfully.')
    }
  }

  handleSuccess = () => {
    var text = "Images in 9 Exposures where uploaded by the photographer -> " + this.props.main.state.photographerName + " to shoot number -> " + this.props.main.state.requestName + " in address -> " + this.props.main.state.shootingLocation
    axios.post('https://wesnapp.co.il/api/slack/photographer-notification',{text: text}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      console.log(response.data);
    })
    .catch( (error) => {
      console.log(error);
    });
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    this.props.enqueueSnackbar("Images in 9 exposures were successfully uploaded",
      { variant: "success", autoHideDuration: 9999999999999999, resumeHideDuration: 99999999999999,
      action: key => (
                    <Button color="secondary" style={{color: 'white'}} size="small" onClick={() => this.props.closeSnackbar(key)}>
                        Dismiss
                    </Button>
            )}
      )
  }

  handleAlert = () => {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    this.props.enqueueSnackbar("Wrong number of images in 9 exposures", { variant: "warning", autoHideDuration: 9999999999999999, resumeHideDuration: 99999999999999,
    action: key => (
                  <Button color="secondary" style={{color: 'white'}} size="small" onClick={() => this.props.closeSnackbar(key)}>
                      Dismiss
                  </Button>
          )}
        )
  }

  uploadProgress = (id) => {
    return (progress) => {
      var arrayOfFiles = this.state.files
      var percentCompleted = Math.round((progress.loaded * 100) / progress.total);
      arrayOfFiles[id].percentCompleted = percentCompleted
      this.setState({files: arrayOfFiles}, () => {
      })
    }
  }

  getNameFromUrl = (item) => {
    var splitedUrl = item.url.split('/')
    return splitedUrl[splitedUrl.length - 1]
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root}>
        <Grid container spacing={24} dir="ltr">
          <Grid item xs={12} sm={12} md={12}>
            <div>
              <Dropzone onFilesAdded={this.handleChangeFiles.bind(this)} />
              <div style={{marginTop: 0, width: '100%', marginLeft: -2.5}}>
                {this.state.files.length > 0 && (
                  <div>
                    <div style={{fontWeight: 'bold', fontFamily: 'arial', fontSize: 20, marginBottom: 20, marginTop: 15, textAlign: 'left', color: '#818BA6'}}>{this.state.files.length} New images</div>
                    {this.state.files.map((item, i) => (
                      <div>
                        <div style={{width: '100%', height: 50, marginBottom: 10, padding: 5,  alignItems: 'center', boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.09)'}} className="imageCell">
                          {item.percentCompleted == 100 && (
                            <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginRight: 9, marginLeft: 9}}>
                              <img style={{width: 18, height: 18, borderRadius: 9, backgroundColor: 'rgb(120,203,117)', marginTop: 10}} src={vIcon} alt="..." />
                            </div>
                          )}
                          {item.percentCompleted < 100 && (
                            <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginRight: 9, marginLeft: 9, marginTop: 8}}>
                              <CircularProgress size={20} variant="static" value={item.percentCompleted}/>
                            </div>
                          )}
                          <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginRight: 5, marginTop: 9}}>{item.file.name}</div>
                          {item.percentCompleted < 100 && (
                            <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginRight: 5, marginTop: 9}}> - {item.percentCompleted}%</div>
                          )}
                          {item.url != "" && (
                            <div style={{display: 'inline-block', fontSize: 16, float: 'right', marginRight: 5}}>
                              <LazyLoad offset={100}>
                                <img src={item.url} style={{objectFit: 'scale-down', height: 40}} alt="..." />
                              </LazyLoad>
                            </div>
                          )}
                        </div>
                        {((i+1) != 1) && ((i+1) % this.state.exposure == 0) && (<div style={{width: '100%', height: 20}}></div>)}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    )}

  }

Upload4Exposures.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(
    withSnackbar(Upload4Exposures),
);
