// B1 - business and B2 - visitor
// L1 -
// E2 - Invester Visa
// E1 - International Trader
// National Interest
// Lottery

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import Cookies from 'universal-cookie';
import uuid from 'react-native-uuid';
import ImageUploader from 'react-images-upload';
import '../../../App.css';
// import RawOrBrackets from './RawOrBrackets.js';
import {DropzoneArea} from 'material-ui-dropzone';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
// import MuiDialogTitle from '@material-ui/core/DialogTitle';
// import MuiDialogContent from '@material-ui/core/DialogContent';
// import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Gallery from 'react-grid-gallery';
import Dropzone from "../Dropzone/Dropzone";
import Input from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import CloudDownload from '@material-ui/icons/CloudDownload';
// import GifLoader from 'react-gif-loader';
import Avatar from '@material-ui/core/Avatar';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Lightbox from 'react-image-lightbox';
// import ReactImageProcess from "react-image-process";
import { AuthController } from 'react-controllers'
// import LazyLoad from 'react-lazy-load';
import LazyLoad from 'react-lazyload'
import Tooltip from '@material-ui/core/Tooltip';
import 'react-image-lightbox/style.css';

const GET_BRACKETS_IMAGES = 'https://py.wesnapp.co.il/server/get-images-orgenized/'

const uploadIcon = require("../uploadIcon.png");
const downloadIcon = require("../downloadIcon.png");

// const loadingGif = require("./loadingGif.gif");
const vIcon = require("../vIcon.png");
const menuIcon = require("../menuIcon.png");
const xIcon = require("../xIcon.png");
const zoomIn = require("../zoomIn.png");

//https://www.iconfinder.com/icons/2561262/cloud_download_icon
const trashIcon = require("../trashTableIcon.png");
const zoomInIcon = require("../zoomInTableIcon.png");
const downloadTIcon = require("../downloadTableIcon.png");
const cameraIcon = require("../cameraTableIcon.png");

const dateIcon = require("../dateIcon.png");
const locationIcon = require("../locationIcon.png");
const userIcon = require("../userIcon.png");
const imageIcon = require("../imageIcon.png");

const alertIcon = require("../alertIcon.png");
const successIcon = require("../successIcon.png");
const zipIcon = require("../zipIcon.png");

const zoomInIconBig = require("../zoomin_icon.png");


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
  },
  margin: {
    margin: 10
  }
});

class Uploaded5Exposures extends React.Component {

  state = {
    exposure: 5,
    openGallery: false,
    index: 0
  };

  getName = (url) => {
    var arr = url.split('/')
    var last = arr[arr.length - 1]
    return last.replace('_reduced_thumbnail.', '')
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root}>
        {this.state.openGallery && (
          <Lightbox
            mainSrc={this.props.images.compressed[this.state.index]}
            nextSrc={this.props.images.compressed[(this.state.index + 1) % this.props.images.compressed.length]}
            prevSrc={this.props.images.compressed[(this.state.index + this.props.images.compressed.length - 1) % this.props.images.compressed.length]}
            onCloseRequest={() => this.setState({ openGallery: false })}
            onMovePrevRequest={() =>
              this.setState({
                index: (this.state.index + this.props.images.compressed.length - 1) % this.props.images.compressed.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                index: (this.state.index + 1) % this.props.images.compressed.length,
              })
            }
          />
        )}
        <Grid container spacing={24} dir="ltr">
          <Grid item xs={12} sm={12} md={12}>
          <div>
            <div style={{marginTop: 0, width: '100%', marginLeft: -2.5}}>
              {this.props.images.loading && (
                <center>
                  <CircularProgress style={{marginTop: 20}}/>
                  <div style={{marginTop: 20, fontSize: 20, fontFamily: 'arial', color: '#818BA6'}}>Loading Images</div>
                </center>
              )}
              {(this.props.images.loading == false && this.props.images.thumbnail.length == 0) && (
                <center>
                  <div style={{marginTop: 20, fontSize: 20, fontFamily: 'arial', color: '#818BA6'}}>0 Images</div>
                </center>
              )}
              {(this.props.images.loading == false && this.props.images.thumbnail.length > 0) && (
                <div style={{fontWeight: 'bold', fontFamily: 'arial', fontSize: 20, marginBottom: 20, textAlign: 'left', color: '#818BA6'}}>{this.props.images.thumbnail.length} Images</div>
              )}
              {this.props.main.state.displayGallery == false ? (
                <div>
                    <div>
                    {this.props.images.thumbnail.map((item, i) => (
                      <div>
                        <div style={{width: '100%', height: 50, marginBottom: 10, padding: 5,  alignItems: 'center', boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.09)'}} className="imageCell">
                          <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginRight: 9, marginLeft: 9}}>
                            <img style={{width: 18, height: 18, borderRadius: 9, backgroundColor: 'rgb(120,203,117)', marginTop: 10}} src={vIcon} alt="..." />
                          </div>
                          <div style={{display: 'inline-block', fontSize: 16, float: 'left', marginRight: 5, marginTop: 9}}>{this.getName(item)}</div>
                          {item != "" && (
                            <div style={{display: 'inline-block', fontSize: 16, float: 'right', marginRight: 5}}>
                              <LazyLoad offset={100}>
                                <img src={item} style={{objectFit: 'scale-down', height: 40}} alt="..." />
                              </LazyLoad>
                            </div>
                          )}
                          <Tooltip title={"Delete"} placement="top">
                            <div onClick={() => this.props.main.deleteImage('5Exposures', i)} style={{display: 'inline-block', fontSize: 16, float: 'right', right: 0, marginRight: 9, marginTop: 8}} className="buttonIcon">
                              <img src={trashIcon} style={{objectFit: 'scale-down', height: 22, fill: 'red'}} alt="..." />
                            </div>
                          </Tooltip>
                          <Tooltip title={"Download"} placement="top">
                            <div onClick={() => this.props.main.downloadOneImage(this.props.images.originals[i], this.props.images.originals[i].split('/').slice(-1)[0])} style={{display: 'inline-block', fontSize: 16, float: 'right', marginRight: 9, marginTop: 8}} className="buttonIcon">
                              <img src={downloadTIcon} style={{objectFit: 'scale-down', height: 22}} alt="..." />
                            </div>
                          </Tooltip>
                          <Tooltip title={"Zoom In"} placement="top">
                            <div onClick={() => {this.setState({openGallery: true, index: i})}} style={{display: 'inline-block', fontSize: 16, float: 'right', marginRight: 9, marginTop: 8, filter: 'opacity(1.0) drop-shadow(150 150 150 red)'}} className="buttonIcon">
                              <img src={zoomInIcon} style={{objectFit: 'scale-down', height: 22}} alt="..." />
                            </div>
                          </Tooltip>
                        </div>
                        {((i+1) != 1) && ((i+1) % this.state.exposure == 0) && (<div style={{width: '100%', height: 20}}></div>)}
                        </div>
                      ))}
                    </div>
                </div>
              ) : (
                <div>
                  {this.props.images.imagesForGallery.length > 0 && (
                    <div>
                    {
                      // className={item.isSelected ? ("boxWrapper") : ("")}
                    }
                    <div style={{display: 'inline-block', width: 'calc(100% - 0px)', verticalAlign:'top'}}>
                      {this.props.images.imagesForGallery.map((item, i) => (
                        <div style={{display: 'inline-block', width: '18%',marginBottom: 10, marginLeft: 10}}>
                          <div className="imgcontainer">
                            <img src={item.src} alt="Avatar" className="image" style={{width: '100%'}} />
                            <div className="middle">
                              <img src={zoomInIconBig} alt="Avatar" className="text" width="80px" height="80px" onClick={() => this.setState({openGallery: true, index: i})}/>
                              <div className="text">{this.props.images.originals[i].split('/').slice(-1)[0]}</div>
                            </div>
                            <div className="boxinimage">
                              <div style={{marginTop: 47}}>
                                <div style={{display: 'inline-block', fontSize: '19px', marginTop: -8, textAlign: 'left', float: 'left', marginLeft: 8}}>Photo #{i+1}</div>
                                <button style={{display: 'inline-block', cursor: 'pointer', height: 36, marginTop: -12, float: 'right', border: 'none', outline: 'none', backgroundColor: 'clear', background: 'none'}} onClick={() => this.props.main.deleteImage('3Exposures', i)} >
                                  <Delete style={{width: 25, height: 25, color: 'white'}} />
                                </button>
                                <button style={{display: 'inline-block', cursor: 'pointer', height: 36, marginTop: -12, float: 'right', border: 'none', outline: 'none', backgroundColor: 'clear', background: 'none'}} onClick={ ()=> this.props.main.downloadOneImage(this.props.images.originals[i], this.props.images.originals[i].split('/').slice(-1)[0])}>
                                  <CloudDownload style={{width: 25, height: 25, color: 'white'}} />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          </Grid>
        </Grid>
      </div>
    )}
  }

Uploaded5Exposures.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Uploaded5Exposures);
