import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import AddAPhoto from '@material-ui/icons/AddAPhoto';
import NewOrder from '@material-ui/icons/FiberNew';
import SettingsApplication from '@material-ui/icons/SettingsApplications';
import Person from '@material-ui/icons/Person';
import Notifications from '@material-ui/icons/Notifications';
import AddPhotoAlternate from '@material-ui/icons/AddToPhotos';
import ATImages from '@material-ui/icons/TouchApp';
import Photo from '@material-ui/icons/PhotoLibrary';
import CircularProgress from '@material-ui/core/CircularProgress';
// const cameraIcon = require("../../Assets/Images/cameraTableIcon.png");

// const dateIcon = require("../../Assets/Images/dateIcon.png");
// const locationIcon = require("../../Assets/Images/locationIcon.png");
// const userIcon = require("../../Assets/Images/userIcon.png");

const whiteIcon = require("../../Assets/Images/WhiteIcon.png");

const styles = theme => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    height: '100%',
    width: '200px',
    backgroundColor: 'white'
  }
});



class Template extends React.Component {

  // handleChange = (value) => {
    // this.props.main.setState({companyType: value})
    // this.setState({ value });
  //   if (value == 'Upload') {
  //     this.props.main.setState({uploadAndLastUpload: 'Upload'})
  //   } else if (value == 'LastUpload') {
  //     this.props.main.get1Exposures()
  //     this.props.main.get3Exposures()
  //     this.props.main.get4Exposures()
  //     this.props.main.get5Exposures()
  //     this.props.main.get6Exposures()
  //     this.props.main.get7Exposures()
  //     this.props.main.get8Exposures()
  //     this.props.main.get9Exposures()
  //     this.props.main.setState({uploadAndLastUpload: 'LastUpload'})
  //   } else if (value == 'AngleTouch') {
  //     this.props.main.getAngelTouchImages()
  //     this.props.main.setState({uploadAndLastUpload: 'AngleTouch'})
  //   } else if (value == 'UploadEdited') {
  //     this.props.main.setState({uploadAndLastUpload: 'UploadEdited'})
  //   } else if (value == 'EditedImages') {
  //     this.props.main.getEditedImages()
  //     this.props.main.setState({uploadAndLastUpload: 'EditedImages'})
  //   }
  // };

  render() {
    const { classes, theme } = this.props;
    return (
      <div style={{width: '120px', minHeight: '100vh', backgroundColor: 'clear', display: 'inline-block', verticalAlign:'top', fontSize: 14}}>
        <img style={{width: 50, height: 'auto', position: 'absolute', top: 45, left: 32.5}} src={whiteIcon} alt="..." />
        <div className="centerbuttons">
          <div  onClick={()=> {this.props.main.setState({uploadAndLastUpload: 'Upload'})}} style={{cursor: 'pointer', width: '120px', paddingTop: 25, paddingBottom: 25, backgroundColor: this.props.main.state.uploadAndLastUpload == 'Upload' ? ("rgba(255,255,255,0.05") : ("rgba(255,255,255,0")}}>
            <div>
              <AddAPhoto size="25" color="white" style={{color: 'white'}}/>
            </div>
            <div style={{marginTop: 4, color: 'white'}}>
              Upload Originals
            </div>
          </div>
          <div onClick={()=> {this.props.main.setState({uploadAndLastUpload: 'LastUpload'})}}  style={{cursor: 'pointer', width: '120px',  marginTop: '40%', paddingTop: 25, paddingBottom: 25, backgroundColor: this.props.main.state.uploadAndLastUpload == 'LastUpload' ? ("rgba(255,255,255,0.05") : ("rgba(255,255,255,0")}}>
            <div>
              <PhotoCamera size="25" color="white" style={{color: 'white'}}/>
            </div>
            <div style={{marginTop: 4, color: 'white'}}>
              Originals Images
            </div>
          </div>
          {
          // <div onClick={()=> {this.props.main.setState({uploadAndLastUpload: 'AngleTouch'})}}  style={{cursor: 'pointer', marginTop: '40%', width: '120px', paddingTop: 25, paddingBottom: 25, backgroundColor: this.props.main.state.uploadAndLastUpload == 'AngleTouch' ? ("rgba(255,255,255,0.05") : ("rgba(255,255,255,0")}}>
          //   <div>
          //     <ATImages size="25" color="white" style={{color: 'white'}}/>
          //   </div>
          //   <div style={{marginTop: 4, color: 'white'}}>
          //     AT Images
          //   </div>
          // </div>
          // <div onClick={()=> {this.props.main.setState({uploadAndLastUpload: 'UploadEdited'})}}  style={{cursor: 'pointer', marginTop: '40%', width: '120px', paddingTop: 25, paddingBottom: 25, backgroundColor: this.props.main.state.uploadAndLastUpload == 'UploadEdited' ? ("rgba(255,255,255,0.05") : ("rgba(255,255,255,0")}}>
          //   <div>
          //     <AddPhotoAlternate size="25" color="white" style={{color: 'white'}}/>
          //   </div>
          //   <div style={{marginTop: 4, color: 'white'}}>
          //     Upload Edited
          //   </div>
          // </div>
          // <div onClick={()=> {this.props.main.setState({uploadAndLastUpload: 'EditedImages'})}}  style={{cursor: 'pointer', marginTop: '40%', width: '120px', paddingTop: 25, paddingBottom: 25, backgroundColor: this.props.main.state.uploadAndLastUpload == 'EditedImages' ? ("rgba(255,255,255,0.05") : ("rgba(255,255,255,0")}}>
          //   <div>
          //     <Photo size="25" color="white" style={{color: 'white'}}/>
          //   </div>
          //   <div style={{marginTop: 4, color: 'white'}}>
          //     Edited Images
          //   </div>
          // </div>
          }
        </div>
      </div>
    );
  }
}

Template.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Template);
