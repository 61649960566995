import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import CircularProgress from '@material-ui/core/CircularProgress';
import UploadImages from './UploadImages';
import { SnackbarProvider, useSnackbar } from 'notistack';

// import Order from './Order';

// const cameraIcon = require("../../Assets/Images/cameraTableIcon.png");
//
// const dateIcon = require("../../Assets/Images/dateIcon.png");
// const locationIcon = require("../../Assets/Images/locationIcon.png");
// const userIcon = require("../../Assets/Images/userIcon.png");
// const imageIcon = require("../../Assets/Images/imageIcon.png");

const styles = theme => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    width: '100vw',
    height: '100vh',
    backgroundColor: 'red'
  }
});

class Template extends React.Component {

  state = {
    deviceType: 'desktop',
    uploadAndLastUpload: 'Upload',
    page: ''
  }

  componentDidMount() {
    var page = this.getParameterByName('page')
    this.setState({page: page})
  }

  getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <div style={{width: 'calc(100vw - 120px)', minHeight: '100vh', backgroundColor: 'white', display: 'inline-block', borderRadius: '35px 0px 0px 35px', verticalAlign:'top', overflowY: 'scroll', height: '100vh', position: 'sticky', position: '-webkit-sticky', top: 0, position: 'absolute', top: 0, left: '120px'}}>
      <SnackbarProvider maxSnack={10}>
        <UploadImages main={this}/>
      </SnackbarProvider>
{
  // {this.state.page == 'shooting' && (<Shooting main={this}/>)}
  // {this.state.page == 'order' && (<Order main={this}/>)}
}
      </div>
    );
  }
}

Template.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Template);
